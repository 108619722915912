import { lazy, Suspense } from "react";
import { community } from "./community";
import { home } from "./home";
import { read } from "./read";
import { auth } from "./auth";
import { other } from "./other";
import { user } from "./user";
import { work } from "./work";
import Loading from "../components/my/MySpin";
import NotFound from "../components/base/NotFound";

const Star = lazy(() => import('../pages/star'))
const Read = lazy(() => import('../pages/read'))
const Books = lazy(() => import('../pages/books'))

export type Route = {
    path: string
    element: JSX.Element
    extra?: boolean
    children?: Route[],
    meta?: {
        banner?: string
        flowers?: boolean
        check?: boolean
        author?: boolean
    }
}

export const routes: Array<Route> = [
    ...home,
    ...auth,
    ...read,
    ...user,
    ...work,
    ...other,
    ...community,
    { 
        path: '/star', 
        element: (
            <Suspense fallback={<Loading />}>
                <Star />
            </Suspense>
        ),
        meta: { banner: 'starPage.title', check: true },
    },
    { 
        path: '/read', 
        element: (
            <Suspense fallback={<Loading />}>
                <Read />
            </Suspense>
        )
    },
    { 
        path: '/books', 
        element: (
            <Suspense fallback={<Loading />}>
                <Books />
            </Suspense>
        ),
        meta: { banner: 'bookmarkPage.title', check: true },
    },
    {
        path: '*',
        element: (
            <Suspense fallback={<Loading />}>
                <NotFound />
            </Suspense>
        )
    }
]