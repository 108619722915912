import React, { memo, useContext } from 'react'
import { Button } from 'antd'
import { Link } from 'react-router-dom'
import { ReactComponent as ChatSVG }  from '../../assets/svg/chat.svg'
import { HeaderCustomContext } from '.'
import { useAuthentication } from '../../hooks/useAuthentication'
import { useLanguage } from '../../hooks/useLanguage'

const Chat: React.FC = memo(() => {
  // context
  const { isTransparent } = useContext(HeaderCustomContext)
  
  // hooks
  const { stayTuned } = useAuthentication()
  const { t } = useLanguage()

  return (
    <Link to='/community/chat' onClick={stayTuned}>
      <Button 
        size='large' 
        type='primary'
        className={['flex items-center gap-[6px] text-sm px-[24px] py-[11px] transition duration-500', isTransparent?"bg-white bg-opacity-10 border-[1px] border-solid border-white":""].join(" ")}>
          <ChatSVG />
          <span className='text-white'>{ t()['header']['chat'] }</span>
      </Button>
    </Link>
  )
})

export default Chat