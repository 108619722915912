import { WorkVo } from "../../vo/work.vo"
import { http } from "../request"

export const workFindUniqueApi = async (id: number, accessToken: string, withDraft: boolean) => {
    return accessToken
    ? await http.get<WorkVo>( `/work/${id}/${withDraft ? "DRAFT" : ""}`, {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
    : await http.get<WorkVo>(`/work/${id}`)
}
