import React, { memo, useContext, useState } from 'react'
import { Button, Form, FormProps, Input } from "antd";
import { ScrollToTopLink } from '../../components/base/ScrollToTopLink';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetPasswordApi } from '../../api/account/resetPassword';
import { AntdProviderContext } from '../../providers/AntdProvider';
import { ViewportProviderContext } from '../../providers/ViewportProvider';

type FieldType = {
    password: string
    passwordConfirmed: string
}

const Reset: React.FC = memo(() => {
    // router
    const navigate = useNavigate()
    const { search } = useLocation()
    const searchParams = new URLSearchParams(search)
    const email = searchParams.get('email') || ''
    const token = searchParams.get('token') || ''

    // context
    const { messageApi } = useContext(AntdProviderContext)
    const { notDesktop } = useContext(ViewportProviderContext)

    // responsive data
    const [loading, setLoading] = useState<boolean>(false)

    // method: 重置密码
    const handleReset: FormProps<FieldType>['onFinish'] = async (values) => {
        const { password } = values
        setLoading(true)
        const res = await resetPasswordApi({ email, token, password })
        if(!(res as any).error) {
            messageApi.success("修改成功")
            navigate('/auth/login')
        }
        setLoading(false)
    }

    return (
        <div className={[
            ' relative tracking-[2px] overflow-auto flex flex-col justify-center items-center px-[11%]',
            notDesktop ? 'w-full gap-4 py-16' : 'min-h-[calc(100vh-108px-92px)] w-1/2 py-20 gap-8'
        ].join(' ')}>
            <div className='flex flex-col items-center'>
                <div className="font-semibold text-[3rem] text-primary">重置密碼</div>
            </div>
            <Form 
                name="reset"
                onFinish={handleReset}
                autoComplete="off"
                className='flex flex-col gap-3 w-full'
            >
                <div className='mb-3'>密碼</div>
                <Form.Item<FieldType>
                    name="password"
                    rules={[
                        { required: true, message: '請輸入您的密碼！' },
                        { min: 8, message: '密碼長度必須介於 8 到 30 個字元' },
                        { max: 30, message: '密碼長度必須介於 8 到 30 個字元' },
                    ]}
                >
                    <Input 
                        type='password'
                        placeholder="請輸入密碼" 
                        className="h-14 text-base px-4"
                    />
                </Form.Item>
                <div className='mb-3'>確認密碼</div>
                <Form.Item<FieldType>
                    name="passwordConfirmed"
                    rules={[
                        { required: true, message: '請輸入您的確認密碼!' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                            if (!value || getFieldValue('password') === value) return Promise.resolve()
                            return Promise.reject(new Error('確認密碼與密碼請保持一致！'))
                            },
                        }),
                    ]}
                >
                    <Input 
                        type='password'
                        placeholder="請輸入確認密碼" 
                        className="h-14 text-base px-4"
                    />
                </Form.Item>
                <ScrollToTopLink to='/auth/login'>
                    <div className="text-gray text-center pt-3 pb-5">返回登入？</div>
                </ScrollToTopLink>
                <Form.Item>
                    <Button 
                        type="primary" 
                        htmlType="submit"
                        className="w-full h-14 text-base"
                        loading={loading}
                    >提交</Button>
                </Form.Item>
            </Form>
        </div>
    )
})

export default Reset