import React, { memo, useContext } from 'react'
import { routes } from '../router'
import { matchRoutes, useLocation } from 'react-router-dom'
import BackGroundBase from '../components/base/BackGroundBase'
import { ViewportProviderContext } from './ViewportProvider'

interface FlowersProviderProps {
  children: JSX.Element
}

// Provider: 根据当前路由根结点配置，判断是否显示背景花朵图案
const FlowersProvider: React.FC<FlowersProviderProps> = memo(({ children }) => {
  const { pathname } = useLocation()
  const match = matchRoutes(routes, pathname)
  const root = match && match[0].route
  const meta = root && root.meta
  // const config = match?.find(config => config.pathname === pathname)
  // const route = config && config.route
  
  const flowers = (typeof meta?.flowers === 'boolean') ?  meta.flowers : true

  // context
  const { notDesktop } = useContext(ViewportProviderContext)
  
  return (
    <>
      { children }
      { 
        notDesktop 
        ? <BackGroundBase flowers={false} /> 
        : <BackGroundBase flowers={flowers}/> 
      }
    </>
  )
})

export default FlowersProvider