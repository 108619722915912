import React, { memo, useState, useEffect, useContext } from 'react'
import { Button } from 'antd'
import { Link, matchRoutes } from 'react-router-dom'
import EditSVG from '../../assets/svg/edit-light.svg'
import EditDarkSVG from "../../assets/svg/edit-dark.svg"
import { HeaderCustomContext } from '.'
import { routes } from '../../router'
import { RootState, useAppSelector } from '../../redux'
import { useAuthentication } from '../../hooks/useAuthentication'

interface EditProps {
  mode?: 'mobile' | 'desktop';
}

const Edit: React.FC<EditProps> = memo(({ mode = 'desktop' }) => {
  // context
  const { isTransparent } = useContext(HeaderCustomContext)

  // responsive data
  const [editIcon, setEditIcon] = useState(isTransparent ? EditDarkSVG : EditSVG)

  // hooks
  const { pleaseLogin, onlyAuthor } = useAuthentication()

  // redux
  const { isLogin, user } = useAppSelector((state: RootState) => state.authReducer)

  // router
  const match = matchRoutes(routes, "/work/bench")
  const root = match && match[0].route
  const meta = root && root.meta

  // watch: 判断当前展示的是暗色组件还是亮色组件
  useEffect(() => {
    setEditIcon(isTransparent ? EditDarkSVG : EditSVG)
  }, [isTransparent])

  return (
    <Link to='/work/bench'>
      <Button 
        onClick={(meta?.check && !isLogin) ? pleaseLogin : (meta?.author && !user?.author) ? onlyAuthor : undefined}
        size='large'
        onMouseEnter={() => setEditIcon(isTransparent ? EditSVG : EditSVG)}
        onMouseLeave={() => setEditIcon(isTransparent ? EditDarkSVG : EditSVG)}
        className={`transition duration-500 p-0 flex justify-center items-center border-[#102E24] bg-white bg-opacity-10 ${mode === 'mobile' ? 'h-8 w-full' : 'aspect-square'}`}
      >
        <img src={editIcon} alt="" />
        {mode === "mobile"?
          <span className="ml-2">工作台</span>
          :
          null
        }
      </Button>
    </Link>
  )
})

export default Edit