import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { CollectibleVo } from "../../vo/collectible.vo"
import { WorkVo } from "../../vo/work.vo"
import { recommendFindApi } from "../../api/recommend/find"

interface HomeState {
    heroCollectibles: CollectibleVo[]
    recommendCollectibles: CollectibleVo[]
    recommendBooks: WorkVo[]
    tagsCollectibles: {
        tag_id: number,
        collectibles: CollectibleVo[]
    }[],
    recommendLoading: boolean
}

// 获取推荐页数据
export const recommendFindAsync = createAsyncThunk(
    "home/recommendFindAsync",
    async () => {
        return await recommendFindApi()
    }
)

const initialState: HomeState = {
    heroCollectibles: [],
    recommendCollectibles: [],
    recommendBooks: [],
    tagsCollectibles: [],
    recommendLoading: false,
}

export const homeSlice = createSlice({
    name: "home",
    initialState,
    reducers: {
        setRecommendLoading: (state, action: PayloadAction<boolean>) => ({ ...state, recommendLoading: action.payload }),
    },
    extraReducers(builder) {
        builder
        // recommendFindAsync
        .addCase(recommendFindAsync.fulfilled, (state, action) => {
            const payload = action.payload
            if(payload) {
                state.heroCollectibles = payload.twoCollectibles
                state.recommendCollectibles = payload.tenCollectibles
                state.recommendBooks = payload.works
                state.tagsCollectibles = payload.tagsCollectibles
            }
        })
    }
})

export const {
    setRecommendLoading
} = homeSlice.actions