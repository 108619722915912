import { WorkVo } from "../../vo/work.vo"
import { http } from "../request"

export const workCreateApi = async (body: FormData, accessToken: string) => {
    return await http.post<WorkVo>("/work", body, {
        headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/form-data"
        }
    })
}
