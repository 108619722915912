import React from 'react';
import { IoIosArrowBack } from 'react-icons/io';

interface BackButtonProps {
    title: string;
    onClickFunction: () => void;
    className?: string; // Optional className prop
}

const BackButton: React.FC<BackButtonProps> = ({ title = '返回', onClickFunction, className }) => {
    return (
        <div
            className={`flex items-center gap-2 left-0 bottom-0 cursor-pointer ${className || ''}`}
            onClick={onClickFunction}
        >
            <IoIosArrowBack className="custom-back-icon" />
            {title}
        </div>
    );
};

export default BackButton;