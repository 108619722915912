import React, { Fragment, memo, useContext, useMemo, useState } from 'react'
import Book from '../../components/book/Book'
import { WorkVo } from '../../vo/work.vo'
import { Empty, Pagination, Spin } from 'antd'
import NavBar from '../base/NavBar'
import { ViewportProviderContext } from '../../providers/ViewportProvider'

interface BookListProps {
    data: WorkVo[]
    loading: boolean
    headerSlot?: JSX.Element
    showNavBar?: boolean
}

export const BookList: React.FC<BookListProps> = memo(({ data, loading, headerSlot, showNavBar = true }) => {
    // responsive data
    const pageSize = 8
    const [total, setTotal] = useState<number>(1)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [workTagId, setWorkTagId] = useState<number | "all">("all")
    const [workTypeId, setWorkTypeId] = useState<number | "all">("all")

    // context
    const { notDesktop } = useContext(ViewportProviderContext)

    // computed: 计算当前页数据
    const computed = useMemo(() => {
        const startIndex = (currentPage - 1) * pageSize
        const endIndex = startIndex + pageSize
        const filtered = data.filter(item => {
            return (item.main_tag_id === workTagId || item.sub_tag_ids.includes(workTagId as number) || workTagId === "all")
            && (item.work_type_id === workTypeId || workTypeId === "all")
        })
        setTotal(filtered.length)
        return filtered.slice(startIndex, endIndex)
    }, [data, currentPage, workTypeId, workTagId])

    // method: 处理换页
    const handelChangePage = (page: number, _pageSize: number) => {
        setCurrentPage(page)
    }

    return (
        <main>
            <header
                style={{
                    display: showNavBar ? 'block' : 'none'
                }}
                className={[
                    notDesktop
                    ? 'w-full'
                    : 'mx-auto min-w-[960px] flex flex-col gap-6'
                ].join(' ')}
            >
                <NavBar
                    setCurrentPage={setCurrentPage}
                    changeWorkTypeId={id => setWorkTypeId(id)}
                    changeWorkTagId={id => setWorkTagId(id)}
                />
                <section
                    className={[
                        notDesktop
                        ? 'text-center py-5 px-5'
                        : 'mx-auto w-3/5 min-w-[960px]'
                    ].join(' ')}
                >
                    { headerSlot }
                </section>
            </header>
            {
                loading
                ? <Spin className='w-full py-20'/>
                : (computed.length > 0)
                ? (
                    <Fragment>
                        <article
                            className={[
                                'grid',
                                notDesktop
                                ? 'grid-cols-1 px-5 gap-4'
                                : 'grid-cols-2 mx-auto gap-x-6 w-3/5 min-w-[960px] gap-y-12'
                            ].join(' ')}
                        >
                            { computed.map(book => <Book key={book.id} data={book}/>) }
                        </article>
                        <section className='w-full flex justify-center mb-20 py-5'>
                            <Pagination
                                defaultCurrent={1}
                                total={total}
                                current={currentPage}
                                pageSize={pageSize} 
                                showSizeChanger={false}
                                onChange={handelChangePage}
                                hideOnSinglePage
                            />
                        </section>
                    </Fragment>
                )
                : <Empty className='mx-auto py-20' description="沒有符合條件的作品"/>
            }
        </main>
    )
})