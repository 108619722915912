import { http } from "../request"

interface CollectibleAddShelfResponse {
    collector_id: number
    collectible_id: number
}

export const collectibleAddShelfApi = async (id: number, accessToken: string) => {
    return await http.post<CollectibleAddShelfResponse>(`/collectible/shelf/${id}`, {}, {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
}