import React, { memo } from 'react'

interface DiamondSVGProps {
  size?: string
  className?: string
}

const DiamondSVG: React.FC<DiamondSVGProps> = memo(({ size = '24', className = '' }) => {
  return (
    <svg width={size} className={className} height={size} viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.5152 0.85791H6.27841C4.8262 0.85791 4.10009 0.85791 3.56542 1.26121C3.03075 1.66452 2.83127 2.36269 2.43232 3.75902L0.510018 10.4871C0.578558 10.4518 0.656317 10.4318 0.738734 10.4318H8.72011L10.5152 0.85791ZM0.309595 11.1886L0.190127 11.6067C0.0492941 12.0996 -0.0211223 12.3461 0.0286021 12.5868C0.0783265 12.8275 0.240638 13.0258 0.56526 13.4226L12.3075 27.7743L8.73262 11.4318H0.738734C0.556459 11.4318 0.396965 11.3343 0.309595 11.1886ZM16.318 29.4925C15.7592 30.1678 15.4324 30.5055 15.0161 30.5055C14.5974 30.5055 14.2691 30.1639 13.7045 29.4807L9.75627 11.4318H20.2687L16.318 29.4925ZM17.7149 27.7862L29.4669 13.4226C29.7915 13.0258 29.9538 12.8275 30.0036 12.5868C30.0533 12.3461 29.9829 12.0996 29.842 11.6067L29.7202 11.1804C29.634 11.3306 29.4719 11.4318 29.2863 11.4318H21.2924L17.7149 27.7862ZM29.5234 10.4915L27.5998 3.75903C27.2009 2.36269 27.0014 1.66452 26.4667 1.26121C25.9321 0.85791 25.206 0.85791 23.7537 0.85791H19.5098L21.3049 10.4318H29.2863C29.3721 10.4318 29.4528 10.4534 29.5234 10.4915ZM18.4924 0.85791H11.5326L9.73753 10.4318H20.2875L18.4924 0.85791Z" fill="url(#paint0_linear_454_122765)"/>
      <defs>
        <linearGradient id="paint0_linear_454_122765" x1="-0.768731" y1="6.34822" x2="36.307" y2="7.37798" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EEE0C9"/>
          <stop offset="0.2" stopColor="#ECBCBB"/>
          <stop offset="0.42" stopColor="#B4ADEE"/>
          <stop offset="0.71" stopColor="#B4D5E5"/>
          <stop offset="0.9" stopColor="#F1E8CC"/>
        </linearGradient>
      </defs>
    </svg>
  )
})

export default DiamondSVG