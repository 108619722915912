import React, { Fragment, useContext, useEffect, useMemo } from 'react'
import HeaderCustom from './components/header'
import FooterCustom from './components/footer'
import { matchPath, useLocation, useRoutes } from 'react-router-dom'
import { routes } from './router'
import BannerProvider from './providers/BannerProvider'
import FlowersProvider from './providers/FlowersProvider'
import { useAppDispatch } from './redux'
import { workTagFindAllAsync, workTypeFindAllAsync } from './redux/modules/workSlice'
import { moodFindAllAsync } from './redux/featrue/themeSlice'
import { ViewportProviderContext } from './providers/ViewportProvider'
import { HeaderCustomMobile } from './components/header_mobile'

const App: React.FC = () => {
  // redux
  const dispatch = useAppDispatch()

  // router
  const { pathname } = useLocation()

  // computed: 当前页面是否适配移动端布局
  const isMatch: boolean = useMemo(() => {
    const mobilePages = [
      '/',
      '/home/more',
      '/star',
      '/read',
      '/books',
      '/other/privacy',
      '/other/terms',
      '/other/contact',
      '/auth/login',
      '/auth/register',
      '/auth/forgot',
      '/auth/reset',
      '/auth/verify',
      '/auth/success',
      '/read/book/*',
      '/read/chapter/*',
      '/home/collectible/*',
      '/home/search/*',
      '/user/*',
      '/work/*'
    ]
    for(const mobilePage of mobilePages) {
      if(matchPath(mobilePage, pathname)) {
        return true
      }
    }
    return false
  }, [pathname])

  // mounted: fetch data
  useEffect(() => {
    dispatch(workTypeFindAllAsync()) // fetch work types
    dispatch(workTagFindAllAsync()) // fetch work tags
    dispatch(moodFindAllAsync()) // fetch moods
  }, [dispatch])

  // context
  const { notDesktop } = useContext(ViewportProviderContext)

  // computed: header component
  const HeaderComponent = useMemo(() => {
    return notDesktop ? HeaderCustomMobile : HeaderCustom
  }, [notDesktop])

  return (
    <main 
      style={{ minWidth: isMatch ? '0px' : '1280px' }} 
      className='max-w-[2552px] min-w-[1280px] mx-auto overflow-hidden ping-fang-hk'
    >
      {/* 页面头部 */}
      <HeaderComponent />
      {/* 页面主视区 */}
        <BannerProvider>
          <article className='w-full h-auto relative'>
            <FlowersProvider>
              <Fragment>
                { useRoutes(routes) }
              </Fragment>
            </FlowersProvider>
          </article>
        </BannerProvider>
      {/* 页面底部 */}
      <FooterCustom />
    </main>
  )
}

export default App
