import { WorkVo } from "../../vo/work.vo"
import { http } from "../request"

export interface WorkFindParams {
    workTypeId?: number
    workTagId?: number
    search?: string
    isEnd?: boolean
}

export const workFindApi = async (accessToken?: string, params: WorkFindParams = {}) => {
    // 将 params 由 JavaScript 对象格式转换为 query 格式
    const queryString = Object.keys(params)
        .filter(key => (params as any)[key] !== undefined)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent((params as any)[key])}`)
        .join('&')
    return accessToken 
    ? await http.get<WorkVo[]>(`/work?${queryString}`, {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
    : await http.get<WorkVo[]>(`/work?${queryString}`)
}
