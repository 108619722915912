export const formatTime = (date: Date): string => {
    const now: Date = new Date();
    const diff: number = Math.floor((now.getTime() - date.getTime()) / 1000); // 计算时间差，单位为秒
  
    // 定义时间单位的秒数
    const minute: number = 60;
    const hour: number = 60 * minute;
    const day: number = 24 * hour;
  
    if (diff < minute) {
      return '刚刚';
    } else if (diff < hour) {
      const minutes: number = Math.floor(diff / minute);
      return `${minutes}分钟前`;
    } else if (diff < day) {
      const hours: number = Math.floor(diff / hour);
      return `${hours}小时前`;
    } else if (date.getFullYear() === now.getFullYear()) {
      // 同年的情况下，返回月份和日期
      const month: number = date.getMonth() + 1; // 月份从0开始，需要加1
      const dayOfMonth: number = date.getDate();
      return `${month}月${dayOfMonth}日`;
    } else {
      // 不同年份的情况下，返回月份、日期和年份
      const year: number = date.getFullYear();
      const month: number = date.getMonth() + 1;
      const dayOfMonth: number = date.getDate();
      return `${month}月${dayOfMonth}日 ${year}`;
    }
}