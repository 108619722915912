import { ChapterVo } from "../../vo/chapter.vo"
import { http } from "../request"

export const chapterPublishApi = async (id: number, accessToken: string) => {
    return await http.put<ChapterVo>(`/chapter/${id}`, {}, {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
}
