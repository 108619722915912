import React, { memo, useContext } from 'react'
import LeftTop from '../../assets/svg/background-left-top.svg'
import RightBottom from '../../assets/svg/background-right-bottom.svg'
import Halo from './Halo'
import { ViewportProviderContext } from '../../providers/ViewportProvider'

interface BackGroundBaseProps {
  flowers?: boolean
}

// tip: 页面背景组件
const BackGroundBase: React.FC<BackGroundBaseProps> = memo(({ flowers = true }) => {
  // context
  const { notDesktop } = useContext(ViewportProviderContext)

  return (
    <div className='w-full h-full absolute top-0 left-0 -z-50'>
      {/* 黄色光晕 */}
      <section className={[
        'w-1/3 h-96 absolute',
        notDesktop ? '-top-32 left-0' : 'left-48 -top-40'
      ].join(' ')}>
        <Halo color='#fdf4e2'/>
      </section>
      <section style={{ visibility: flowers?"visible":"hidden" }} className='absolute top-16 left-16'>
        <img src={LeftTop} alt="左上角花卉" />
      </section>
      {/* 绿色光晕 */}
      <section className='w-1/3 h-96 absolute right-48 top-96'>
        <Halo color='#ecf7f6'/>
      </section>
      <section style={{ visibility: flowers?"visible":"hidden" }} className='absolute bottom-12 right-16'>
        <img src={RightBottom} alt="右下角花卉" />
      </section>
    </div>
  )
})

export default BackGroundBase