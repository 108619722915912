import React, { memo } from 'react'
import { Button as AntdButton, ButtonProps } from 'antd'

const MyButton: React.FC<ButtonProps> = memo((props) => {
    const { type, children, className } = props
    switch(type) {
        case "primary": return (
            <AntdButton 
                {...props}
                className={['border-0', className].join(" ")}
            >{children}</AntdButton>
        )
        default: return (
            <AntdButton 
                {...props}
                className={['border-0 bg-[#ebeee8]', className].join(" ")}
            >{children}</AntdButton>
        )
    }
})

export default MyButton