import { useState } from 'react';

// 设置 cookie 函数
const setCookie = (name: string, value: string, days?: number) => {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/; secure; samesite=strict";
}

// 获取 cookie 函数
const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift() || null
    return null
}

// 删除 cookie 函数
const deleteCookie = (name: string) => {
    setCookie(name, "", -1); // 设置一个过去的过期时间来删除 cookie
}

// hook: 用于管理 cookies
const useCookie = (cookieKey: string) => {
    // responsive data
    const [value, setValue] = useState<string | null>(getCookie(cookieKey)) // 当前 cookie 字段的值

    // method: 设置 cookie
    const storeCookie = (value: string, expiresDays?: number) => {
        expiresDays
        ? setCookie(cookieKey, value, expiresDays)
        : setCookie(cookieKey, value)
        setValue(value)
    }

    // method: 删除 cookie
    const removeCookie = () => {
        deleteCookie(cookieKey)
        setValue(null)
    }

    return { 
        value, 
        setValue: storeCookie, 
        deleteValue: removeCookie 
    }
}

export default useCookie
