import React, { memo, useContext } from 'react'
import Halo from '../../components/base/Halo'
import { UserContext } from '../../pages/user'

const Background: React.FC = memo(() => {
  // redux
  const { currentMood } = useContext(UserContext)

  // other data
  const light = `${currentMood?.light}`

  return (
    <aside className='w-full h-full bg-white absolute top-0 left-0 -z-40'>
        <section className='w-1/3 h-96 absolute top-20 left-20'>
          <Halo color={light}/>
        </section>
        <section className='w-1/3 h-96 absolute top-0 left-1/2 translate-y-full'>
          <Halo color={light}/>
        </section>
        <section className='w-1/3 h-96 absolute -top-20 right-0'>
          <Halo color={light}/>
        </section>
    </aside>
  )
})

export default Background