import React, { createContext, memo } from 'react'
import { ConfigProvider, message } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';

interface AntdProviderProps {
    children: JSX.Element
}

type AntdProviderContextType = {
    messageApi: MessageInstance
}

export const AntdProviderContext = createContext<AntdProviderContextType>({} as any)

// Provider: 提供 Ant Design 组件库的全局配置
const AntdProvider: React.FC<AntdProviderProps> = memo(({ children }) => {
    // hook
    const [messageApi, contentHandler] = message.useMessage()

    return (
        <>
            { contentHandler }
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#102E24', // 主题色
                        borderRadius: 10 // 圆角
                    },
                    components: {
                        Table: {
                            cellPaddingBlock: 30,
                            headerBg: "#fff",
                            headerColor: "#D4D4CC",
                            headerSplitColor: "transparent"
                        }, // 表格
                        Slider: {
                            railSize: 20,
                            handleSize: 22,
                            handleSizeHover: 22,
                            trackBg: "#102e24"
                        }, // 滑动条
                    },
                }}
            >
                <AntdProviderContext.Provider value={{ messageApi }}>
                    { children }
                </AntdProviderContext.Provider>
            </ConfigProvider>
        </>
    )
})

export default AntdProvider