import { http } from "../request"

export interface AuthorRegisterBody {
    invite_code: string
    account: {
        username: string
        email: string
        password: string
    }
}

export interface AuthorRegisterResponse {
    accessToken: string
}

export const authorRegisterApi = async (body: AuthorRegisterBody) => {
    return await http.post<AuthorRegisterResponse>("/authors/register", body)
}