import React, { memo, useMemo, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector, RootState } from '../../redux';
import User from '../header/User';
import Login from '../header/Login';
import { Row } from 'antd';
import { LogoLightSVG } from '../svgs/LogoLightSVG';
import { LogoDarkSVG } from '../svgs/LogoDarkSVG';
import { SideMenu } from './SideMenu';


export const HeaderCustomMobile: React.FC = memo(() => {
  // redux
  const { isLogin } = useAppSelector((state: RootState) => state.authReducer);

  // router
  const location = useLocation();

  // state to control side menu visibility
  const [isSideMenuVisible, setSideMenuVisible] = useState(false);

  // computed: 背景是否透明
  const isTransparent: boolean = useMemo(() => location.pathname === '/', [location.pathname]);

  // handler for side menu visibility change
  const handleVisibilityChange = (visible: boolean) => {
    setSideMenuVisible(visible);
  };

  // Effect to handle URL changes
  useEffect(() => {
    setSideMenuVisible(false);
  }, [location]);

  return (
    <main
      className={`select-none z-50 `}
      style={{
        color: isTransparent ? '#fff' : '#102e24',
        backgroundColor: isTransparent ? 'transparent' : '#fff',
      }}
    >
      <Row className={`z-[80] px-8 border-0 border-b-[1px] border-solid border-white border-opacity-20 text-base font-[500] h-20 flex items-center justify-between gap-10 relative ${isTransparent?"":"bg-white"}`}>
        <SideMenu isTransparent={isTransparent} setSideMenuVisibilityFunc={handleVisibilityChange} isSideMenuVisible= {isSideMenuVisible}/>
        <section className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
          <Link to='/' >
            <div className='flex items-center'>
              {isTransparent && !isSideMenuVisible ? <LogoLightSVG /> : <LogoDarkSVG />}
            </div>
          </Link>
        </section>
        {isLogin ? <User /> : <Login />}
      </Row>
    </main>
  );
});