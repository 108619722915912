import React, { memo, useContext } from 'react';
import { Link } from 'react-router-dom';
import { ViewportProviderContext } from '../../providers/ViewportProvider';
import { BiUser } from "react-icons/bi";

const Login: React.FC = memo(() => {
  const { isMobile } = useContext(ViewportProviderContext);

  return (
    <Link to='/auth/login' className='flex justify-center'>
      <span className={`${isMobile ? 'text-xl' : 'text-base'} flex justify-center items-center`}>
        {isMobile ? <BiUser /> : "註冊/登入"}
      </span>
    </Link>
  );
});

export default Login;