import React, { memo } from 'react'

interface ShareFormatSVGProps {
    color: string
    size?: number
}

const ShareFormatSVG: React.FC<ShareFormatSVGProps> = memo(({color, size = 24}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2L12.6247 1.21913L12 0.719375L11.3753 1.21913L12 2ZM11 13.5C11 14.0523 11.4477 14.5 12 14.5C12.5523 14.5 13 14.0523 13 13.5L11 13.5ZM17.6247 5.21913L12.6247 1.21913L11.3753 2.78087L16.3753 6.78087L17.6247 5.21913ZM11.3753 1.21913L6.37531 5.21913L7.62469 6.78087L12.6247 2.78087L11.3753 1.21913ZM11 2L11 13.5L13 13.5L13 2L11 2Z" fill={color}/>
    <path d="M15.12 9H16C17.1046 9 18 9.89543 18 11V14.5V18C18 19.1046 17.1046 20 16 20H8C6.89543 20 6 19.1046 6 18V11C6 9.89543 6.89543 9 8 9H8.64" stroke={color} strokeWidth="2"/>
    </svg>
  )
})

export default ShareFormatSVG