import { Route } from './'
import Loading from '../components/my/MySpin'
import { lazy, Suspense } from 'react'
import Search from '../pages/home/search'
import { CollectibleStar } from '../pages/home/collectible/star'
import { CollectibleSuccess } from '../pages/home/collectible/success'

const Home = lazy(() => import('../pages/home'))
const More = lazy(() => import('../pages/home/more'))
const CollectibleDetail = lazy(() => import('../pages/home/collectible'))

export const home: Route[] = [
    { 
        path: '/', 
        element: (
            <Suspense fallback={<Loading />}>
                <Home />
            </Suspense>
        )
    },
    { 
        path: '/home/more', 
        element: (
            <Suspense fallback={<Loading />}>
                <More />
            </Suspense>
        )
    },
    { 
        path: '/home/collectible/:id', 
        element: (
            <Suspense fallback={<Loading />}>
                <CollectibleDetail />
            </Suspense>
        )
    },
    { 
        path: '/home/collectible/star/:id', 
        element: (
            <Suspense fallback={<Loading />}>
                <CollectibleStar />
            </Suspense>
        )
    },
    { 
        path: '/home/collectible/success/:id', 
        element: (
            <Suspense fallback={<Loading />}>
                <CollectibleSuccess />
            </Suspense>
        )
    },
    { 
        path: '/home/search',
        element: (
            <Suspense fallback={<Loading />}>
                <Search />
            </Suspense>
        )
    },
]