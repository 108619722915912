import { createSlice } from "@reduxjs/toolkit";

export interface FileState {
    heroBackground: string
    loginBackground: string
    registerBackground: string
    forgotBackground: string
}

const initialState: FileState = {
    heroBackground: "https://catnip-public.s3.ap-east-1.amazonaws.com/home-background.png",
    loginBackground: "https://catnip-public.s3.ap-east-1.amazonaws.com/login-decorative-drawing.png",
    registerBackground: "https://catnip-public.s3.ap-east-1.amazonaws.com/register-decorative-drawing.png",
    forgotBackground: "https://catnip-public.s3.ap-east-1.amazonaws.com/forgot-decorative-drawing.png"
}

export const fileSlice = createSlice({
    name: "file",
    initialState,
    reducers: {}
})