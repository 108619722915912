import React, { Fragment, memo, useContext, useEffect, useState } from 'react'
import { ScrollToTopLink } from '../base/ScrollToTopLink'
import CollectSVG from '../svgs/CollectSVG'
import MyButton from '../my/MyButton'
import DiamondSVG from '../svgs/DiamondSVG'
import MyTag from '../my/MyTag'
import { RootState, useAppDispatch, useAppSelector } from '../../redux'
import { WorkProps } from '../../vo/work.vo'
import { Popover, Row } from 'antd'
import { accountFindUniqueAsync } from '../../redux/featrue/accountSlice'
import AuthorSVG from '../svgs/AuthorSVG'
import BookCover from './BookCover'
import { workAddShelfApi } from '../../api/work/addShelf'
import { workRemoveShelfApi } from '../../api/work/removeShelf'
import MyAvatar from '../my/MyAvatar'
import useAccessToken from '../../hooks/useAccessToken'
import { AntdProviderContext } from '../../providers/AntdProvider'
import { ViewportProviderContext } from '../../providers/ViewportProvider'
import { useNavigate } from 'react-router-dom'

// tip: 书籍卡片组件
const Book: React.FC<WorkProps> = memo(({ data }) => {
    // props
    const { id, title, progress, coverUrl, description, sub_tag_ids, main_tag_id, collectibleCount, creator_id, is_shelf } = data

    // router
    const navigate = useNavigate()

    // responsive data
    const [isShelf, setIsShelf] = useState<boolean>(is_shelf)

    // context
    const { messageApi } = useContext(AntdProviderContext)
    const { notDesktop } = useContext(ViewportProviderContext)

    // redux data
    const dispatch = useAppDispatch()
    const { workTags } = useAppSelector((state: RootState) => state.workReducer)
    const { accounts } = useAppSelector((state: RootState) => state.accountReducer)

    // hook
    const { accessToken } = useAccessToken()

    // watch: 解决自动登录后的状态同步问题
    useEffect(() => {
        setIsShelf(is_shelf)
    }, [is_shelf])

    // other data
    const mainTag = workTags.find(workTag => workTag.id === main_tag_id)
    const subTags = workTags.filter(workTag => sub_tag_ids.includes(workTag.id))
    const account = accounts.find(account => account.id === creator_id)
    const Component = title.length > 10 ? Popover : Fragment

    // mounted: 获取作者信息
    useEffect(() => {
        if(!accounts.find(account => account.id === creator_id)) {
            dispatch(accountFindUniqueAsync(creator_id))
        }
    }, [accounts, creator_id, dispatch])

    // method: 加入/移出书架
    const handleShelf = () => {
        if(!accessToken) messageApi.warning("請先進行登錄")
        if(!isShelf) {
            workAddShelfApi(id, accessToken).then((res: any) => {
                if(!res.error) setIsShelf(!isShelf)
            })
        } else {
            workRemoveShelfApi(id, accessToken).then((res: any) => {
                if(!res.error) setIsShelf(!isShelf)
            })
        }
    }


    return (
        <section className='w-full h-full flex gap-6'>
            {/* 书籍封面 */}
            <div className={['aspect-[163/227]', notDesktop ? 'min-w-32 w-32' : 'min-w-40 w-40'].join(' ')}>
                <ScrollToTopLink to={`/read/book/${id}`}>
                    <BookCover coverUrl={coverUrl} main_tag_id={main_tag_id} title={title}/>
                </ScrollToTopLink>
            </div>
            {/* 描述 */}
            <div className='flex-1 relative overflow-hidden'>
                <div className='justify-between gap-20 pb-2 w-full h-auto pr-20'>
                    <Component {...title.length > 10 ? { placement: 'top', content: title } : {}}>
                        <div 
                            className='text-primary font-bold cursor-default overflow-hidden text-ellipsis whitespace-nowrap' 
                        >{ title }</div>
                    </Component>
                    <div className='flex justify-end gap-3 absolute top-0 right-0 w-20'>
                        <span className='flex items-center gap-1 text-xs'>
                            <DiamondSVG size={"20"}/>
                            { collectibleCount }
                        </span>
                        <span onClick={handleShelf} className='flex items-center cursor-pointer'>
                            <CollectSVG size={"20"} fill={isShelf}/>
                        </span>
                    </div>
                </div>
                <div className='flex overflow-auto justify-start gap-2'>
                    {
                        mainTag && (
                            <span>
                                <MyTag color={mainTag.color} text={mainTag.name}/>
                            </span>
                        )
                    }
                    {
                        subTags && subTags.map(subTag => (
                            <span key={subTag.id}>
                                <MyTag color={subTag.color} text={subTag.name}/>
                            </span>
                        ))
                    }
                </div>
                <p 
                    className='text-xs' 
                    style={{
                        height: '4rem',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 4,
                    }}
                >{ description }</p>
                <div className='w-full absolute left-0 bottom-0 flex justify-between gap-5 items-center'>
                    <section className='flex overflow-hidden'>
                        <Row onClick={() => navigate(`/user/${account?.id}`)} className='w-full flex flex-nowrap items-center gap-1'>
                            <MyAvatar src={account?.avatar} />
                            <div className='text-xs overflow-hidden text-ellipsis whitespace-nowrap'>{account?.username}</div>
                            <div className='flex items-center'>
                                <AuthorSVG />
                            </div>
                        </Row>
                    </section>
                    <MyButton 
                        size='small' 
                        className='w-20 rounded-full px-4'
                    >{ progress < 100 ? "編寫中" : "已完結" }</MyButton>
                </div>
            </div>
        </section>
    )
})

export default Book