import React, { memo } from 'react'
import FlowerTwoSVG from '../../components/svgs/FlowerTwoSVG'
import FlowerThreeSVG from '../../components/svgs/FlowerThreeSVG'
import { ScrollToTopLink } from '../../components/base/ScrollToTopLink'
import { Button } from 'antd'

const RegisterSuccess: React.FC = memo(() => {
  return (
    <main className='flex flex-col gap-10 items-center py-32 px-10'>
      <section className='w-auto h-auto relative rotate-[20deg]'>
        <FlowerThreeSVG color='#102e24' />
        <aside className='absolute -top-5 -left-12 -rotate-[20deg]'>
          <FlowerTwoSVG color='#102e24' />
        </aside>
      </section>
      <div className='text-center flex flex-col gap-2'>
        <b className='text-2xl m-0 p-0'>恭喜您註冊成功！</b>
      </div>
      <ScrollToTopLink to='/auth/login'>
        <Button type='primary' size='large' className='w-72 h-14 text-base'>前往登錄</Button>
      </ScrollToTopLink>
    </main>
  )
})

export default RegisterSuccess