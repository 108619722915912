import { Button, Drawer } from 'antd';
import React, { memo, useContext, useEffect, useState } from 'react';
import { ScrollToTopLink } from '../base/ScrollToTopLink';
import { useLanguage } from '../../hooks/useLanguage';
import { createStyles, useTheme } from 'antd-style';
import './SideMenu.css'; // Import the custom CSS file
import XSperator from '../base/XSeperator';
import Search from '../header/Search';
import EditSVG from '../../assets/svg/edit-light.svg'
import MyButton from '../my/MyButton';
import { Link } from 'react-router-dom';
import { BsChatRightText } from "react-icons/bs";
import { AntdProviderContext } from '../../providers/AntdProvider';

interface SideMenuProps {
  isTransparent: boolean;
  setSideMenuVisibilityFunc: (visible: boolean) => void;
  isSideMenuVisible: boolean;
}

const useStyle = createStyles(({ token }) => ({
  'my-drawer-header': {
    paddingTop: '80px',
  },
}));

export const SideMenu: React.FC<SideMenuProps> = memo(({ isTransparent, setSideMenuVisibilityFunc, isSideMenuVisible }) => {
  // hook
  const { t } = useLanguage();
  const { styles } = useStyle();
  const token = useTheme();
  const { messageApi } = useContext(AntdProviderContext);

  // state to control drawer visibility
  const [visible, setVisible] = useState(false);

  const toggleDrawer = () => {
    setSideMenuVisibilityFunc(!visible);
    setVisible(!visible);
  };

  const onClose = () => {
    setSideMenuVisibilityFunc(false);
    setVisible(false);
  };

  useEffect(() => {
    setVisible(isSideMenuVisible);
  }, [isSideMenuVisible]);

  const classNames = {
    header: styles['my-drawer-header'],
  };

  const drawerStyles = {
    body: {
      padding: '20px 30px',
      marginTop: '80px',
    }
  };

  return (
    <div className='SideMenu'>
      <main className='cursor-pointer' onClick={toggleDrawer}>
        <div className='h-6 aspect-square flex justify-center items-center'>
        {visible ? (
          <img src={`${process.env.PUBLIC_URL}/svg/cross.svg`} alt='Close' className='h-[0.85rem] aspect-auto' />
        ) : (
          isTransparent ? 
          <img src={`${process.env.PUBLIC_URL}/svg/menu.svg`} alt='Menu'  className='h-6 aspect-auto'/>
          :
          <img src={`${process.env.PUBLIC_URL}/svg/menu_dark.svg`} alt='Menu'  className='h-6 aspect-auto'/>
        )}
        </div>
      </main>
      <Drawer
        zIndex={70}
        placement='left'
        closeIcon={false}
        onClose={onClose}
        open={visible}
        width='100%'
        classNames={classNames}
        styles={drawerStyles}
      >
        <div className='side-menu-links flex flex-col w-full'>
          <Search fontColor='black' fullWidth={true} onSearch={onClose}></Search>
          <XSperator className={"mt-5"} />
          <ScrollToTopLink to={`/star`} onClick={onClose}>
            <section className='w-full flex justify-start items-center text-base py-[1.5rem] px-6 font-semibold tracking-[.15rem] text-primary'>
              {t()['header']['star']}
            </section>
          </ScrollToTopLink>
          <XSperator />
          <ScrollToTopLink to='/read' onClick={onClose}>
            <section className='w-full flex justify-start items-center text-base py-[1.5rem] px-6 font-semibold tracking-[.15rem] text-primary'>
              {t()['header']['read']}
            </section>
          </ScrollToTopLink>
          <XSperator />
          <ScrollToTopLink to='/books' onClick={onClose}>
            <section className='w-full flex justify-start items-center text-base py-[1.5rem] px-6 font-semibold tracking-[.15rem] text-primary'>
              {t()['header']['bookmark']}
            </section>
          </ScrollToTopLink>
          <div className='flex w-full gap-5 mt-4'>
            <div className='flex-1'>
              <Link to='/work/bench' className='w-32 h-12 font-semibold'>
                <Button className='w-full h-full bg-white border-solid border border-primary text-base ' onClick={onClose}>
                  <img src={EditSVG} alt="" className='h-5' />工作台
                </Button>
              </Link>
            </div>
            <div className='flex-1'>
              <MyButton
                type='primary'
                className='h-12 text-base w-full'
                onClick={() => { messageApi.info("敬請期待") }}
              >
                <BsChatRightText className='mr-1' />社群聊天
              </MyButton>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
});