import { http } from "../request"

interface WorkAddLikeResponse {
    collector_id: number
    work_id: number
}

export const workAddLikeApi = async (id: number, accessToken: string) => {
    return await http.post<WorkAddLikeResponse>(`/work/like/${id}`, {}, {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
}