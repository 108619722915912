import { Button } from 'antd'
import React, { memo } from 'react'
import { useNavigate } from 'react-router-dom'

// tip: 路由错误组件
const NotFound: React.FC = memo(() => {
    const navigate = useNavigate()
    return (
        <div className="flex flex-col items-center justify-center max-h-[1440px] h-[calc(100vh-106px-92px)]">
            <div className="max-w-md text-center">
            <h1 className="text-4xl font-bold text-gray-800 mb-4">404 Not Found</h1>
            <p className="text-lg text-gray-600 mb-8">Sorry, the page you are looking for does not exist.</p>
            <Button 
                type='primary' 
                className='text-base w-40 h-10'
                onClick={() => navigate("/")}
            >
                Go to Home
            </Button>
            </div>
        </div>
    )
})

export default NotFound