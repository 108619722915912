export type EditorParagraphJson = {
    type: "paragraph",
    children: {
        text: string
        bold?: boolean
        underline?: boolean
        collectible?: boolean
    }[]
}[]

export function paragraphsToHtml(paragraphs: EditorParagraphJson) {
    return paragraphs.map(paragraph => {
        return `<p>${paragraph.children.map((child) => {
            let html = child.text;
            if (child.collectible) html = `<em>${html}</em>`;
            if (child.bold) html = `<b>${html}</b>`;
            if (child.underline) html = `<u>${html}</u>`;
            return html;
        }).join('')}</p>`;
    }).join('');
}