// EditorSection.tsx
import React, { useContext } from 'react';
import { Editor } from '@wangeditor/editor-for-react';
import { Spin } from 'antd';
import { IDomEditor } from '@wangeditor/editor';
import { ViewportProviderContext } from '../../providers/ViewportProvider';

interface EditorSectionProps {
    editorKey: string;
    html: string;
    setEditor: (editor: IDomEditor | null) => void;
    editChapterLoading: boolean;
    border: string;
    prefixInput: string;
    titleInput: string;
    handleInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const EditorSection: React.FC<EditorSectionProps> = ({
    editorKey,
    html,
    setEditor,
    editChapterLoading,
    border,
    prefixInput,
    titleInput,
    handleInput
}) => {
  const { isMobile, isDesktop, notDesktop } = useContext(ViewportProviderContext);

    return (
        <section
            style={{ borderColor: border }}
            className={`flex-1 border-[1px] border-solid pt-10 bg-white transition duration-500 mb-20`}
        >
            <div className={`text-center gap-6 flex ${isDesktop?"flex-row": "flex-col" } justify-center my-3`}>
                <div className='flex justify-center items-center'>
                    <span className='text-xl transition duration-500' style={{ color: border }}>第</span>
                    <input
                        type="text"
                        name='prefix'
                        value={prefixInput}
                        onChange={handleInput}
                        className='w-12 text-center text-sm text-[#424242] p-0 px-2 border-0 border-b-[1px] outline-none appearance-none'
                    />
                    <span className='text-xl transition duration-500' style={{ color: border }}>章</span>
                </div>
                <div className='flex justify-center items-center'>
                    <input
                        type="text"
                        name='title'
                        value={titleInput}
                        onChange={handleInput}
                        placeholder='請輸入標題'
                        className='text-[#424242] border-0 text-xl outline-none text-center'
                    />
                </div>
            </div>
            <div className='relative'>
                <Editor
                    key={editorKey}
                    value={html}
                    onCreated={setEditor}
                    mode="simple"
                    style={{
                        height: '1000px',
                        fontSize: isDesktop? "20px" : "18px",
                        overflowY: 'hidden',
                        width: "100%"
                    }}
                />
                {editChapterLoading && <Spin className='absolute top-20 left-1/2 -translate-x-1/2' />}
            </div>
        </section>
    );
};

export default EditorSection;