import { MouseEventHandler, useCallback, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { AntdProviderContext } from "../providers/AntdProvider"

export const useAuthentication = () => {
    // router
    const navigate = useNavigate()

    // context
    const { messageApi } = useContext(AntdProviderContext)

    const pleaseLogin: MouseEventHandler<HTMLAnchorElement> = useCallback((e) => {
        e.preventDefault()
        messageApi.info("請先進行登錄")
        navigate("/auth/login")
    }, [messageApi])

    const onlyAuthor: MouseEventHandler<HTMLAnchorElement> = useCallback((e) => {
        e.preventDefault()
        messageApi.info("您還沒有成爲作者哦~")
    }, [messageApi])

    const stayTuned: MouseEventHandler<HTMLAnchorElement> = useCallback((e) => {
        e.preventDefault()
        messageApi.info("敬請期待")
    }, [messageApi])

    return {
        pleaseLogin,
        onlyAuthor,
        stayTuned
    }
}