import { Button } from "antd";
import { memo, useEffect, useRef, useState } from "react";

interface SignCanvasProps {
    containerWidth: number
    containerHeight: number
    signUrl: string
    setSignUrl: React.Dispatch<React.SetStateAction<string>>
}

// tip: 签名板组件
export const SignCanvas: React.FC<SignCanvasProps> = memo((props) => {
    // props
    const { containerWidth, containerHeight, signUrl, setSignUrl } = props

    // ref
    const canvasRef = useRef<HTMLCanvasElement>(null)

    // responsive data
    const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
    const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null);

    // other data
    let drawing = false

    // mounted: 初始化签名画布
    useEffect(() => {
        const canvas = canvasRef.current
        if (canvas) {
            setCanvas(canvas)
            const context = canvas.getContext("2d")
            if (context) {
                setCtx(context);
                const gradient = context.createLinearGradient(0, 0, canvas.width, 0); // 创建线性渐变 (从左到右)
                gradient.addColorStop(0, 'red'); // 渐变开始颜色
                gradient.addColorStop(1, 'blue'); // 渐变结束颜色
                context.strokeStyle = gradient; // 设置线条样式为渐变色
                context.lineWidth = 1; // 设置线条宽度

                // 如果有初始值, 则加载到画布上
                if (signUrl) {
                    const img = new Image();
                    img.src = signUrl;
                    img.onload = () => {
                        context.drawImage(img, 0, 0);
                    }
                }
            }
        }
    }, [containerWidth, containerHeight, signUrl])

    // method: 开始绘制
    const startDrawingMouse = (e: React.MouseEvent<HTMLCanvasElement>) => {
        e.preventDefault(); // Prevent default touch behavior
        if (!ctx || !canvas) return console.log("startDrawing...failed");
        const { left, top } = canvas.getBoundingClientRect()
        drawing = true;
        ctx.beginPath(); // 开始绘制新路径
        ctx.moveTo(e.clientX - left, e.clientY - top); // 移动到鼠标位置
    }

    const startDrawingTouch = (e: React.TouchEvent<HTMLCanvasElement>) => {
        e.preventDefault(); // Prevent default touch behavior
        if (!ctx || !canvas) return console.log("startDrawing...failed");
        const { left, top } = canvas.getBoundingClientRect()
        drawing = true;
        ctx.beginPath(); // 开始绘制新路径
        ctx.moveTo(e.touches[0].clientX - left, e.touches[0].clientY - top); // 移动到触摸位置
        document.addEventListener('touchmove', preventScroll, { passive: false }); // Lock screen
    }

    // method: 绘制中
    const drawMouse = (e: React.MouseEvent<HTMLCanvasElement>) => {
        e.preventDefault(); // Prevent default touch behavior
        if (!ctx || !canvas) return console.log("draw...failed");
        if (!drawing) return;
        const { left, top } = canvas.getBoundingClientRect()
        ctx.lineTo(e.clientX - left, e.clientY - top); // 绘制直线到鼠标位置
        ctx.stroke(); // 绘制路径
    }

    const drawTouch = (e: React.TouchEvent<HTMLCanvasElement>) => {
        e.preventDefault(); // Prevent default touch behavior
        if (!ctx || !canvas) return console.log("draw...failed");
        if (!drawing) return;
        const { left, top } = canvas.getBoundingClientRect()
        ctx.lineTo(e.touches[0].clientX - left, e.touches[0].clientY - top); // 绘制直线到触摸位置
        ctx.stroke(); // 绘制路径
    }

    // method: 结束绘制
    const stopDrawing = () => {
        drawing = false
        if (canvas) {
            setSignUrl(canvas.toDataURL('image/png')) // 及时更新表单数据
        }
        document.removeEventListener('touchmove', preventScroll); // Unlock screen
    }

    // method: 点击按钮重新簽名
    const clearCanvas = () => {
        if (canvas && ctx) {
            ctx.clearRect(0, 0, canvas.width, canvas.height) // 清空画布
            setSignUrl("") // 及时清空表单数据
        }
    }

    // method: Prevent default scrolling behavior
    const preventScroll = (e: TouchEvent) => {
        e.preventDefault();
    }

    return (
        <main className="flex flex-col gap-3">
            <canvas
                ref={canvasRef}
                width={containerWidth}
                height={containerHeight}
                onMouseDown={startDrawingMouse}
                onMouseMove={drawMouse}
                onMouseUp={stopDrawing}
                onMouseLeave={stopDrawing}
                onTouchStart={startDrawingTouch}
                onTouchMove={drawTouch}
                onTouchEnd={stopDrawing}
            ></canvas>
            <Button onClick={clearCanvas} className="self-end px-6">重新簽名</Button>
        </main>
    )
})