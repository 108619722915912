import React, { memo, useCallback, useMemo } from 'react'
import { routes } from '../router'
import { matchRoutes, useLocation } from 'react-router-dom'
import Banner from '../components/base/Banner'
import { useLanguage } from '../hooks/useLanguage'

interface BannerProviderProps {
  children: JSX.Element
}

// Provider: 根据当前路由配置判断，是否显示相应的横幅组件
const BannerProvider: React.FC<BannerProviderProps> = memo(({ children }) => {
  // router
  const { pathname } = useLocation()
  const match = matchRoutes(routes, pathname)
  // const root = match && match[0].route
  const config = match && match.find(config => config.pathname===pathname)
  const route = config && config.route
  const meta = route && route.meta

  // hook
  const { t } = useLanguage() 

  // method: 将 meta.banner 解析为文本
  const parseText = useCallback((currentT:any, tokens: string[]): string => {
    const token = tokens[0]
    if(tokens.length === 1) {
      return currentT[token]
    } else {
      return parseText(currentT[token], tokens.slice(1))
    }
  }, [])

  // computed: 获取文本
  const text: string = useMemo(() => {
    const tokens = meta?.banner?.split('.')
    return (meta?.banner && tokens) ? parseText(t(), tokens) : ''
  }, [meta, parseText, t])
  
  return (
    <>
      { (meta?.banner) && <Banner key={route?.path} title={text}/> }
      { children }
    </>
  )
})

export default BannerProvider