import React, { Fragment, memo, useEffect, useRef, useState } from 'react'
import AuthorSVG from '../svgs/AuthorSVG'
import { ScrollToTopLink } from '../base/ScrollToTopLink'
import StarFormatSVG from '../svgs/StarFormatSVG'
import ShareFormatSVG from '../svgs/ShareFormatSVG'
import ShareModal from '../modals/collectible/share'
import GiftBeltsSVG from '../svgs/GiftBeltsSVG'
import DiamondSVG from '../svgs/DiamondSVG'
import { RootState, useAppSelector } from '../../redux'
import { CollectibleProps } from '../../vo/collectible.vo'
import { Popover, Row } from 'antd'
import { PatternOneSVG } from '../svgs/PatternOneSVG'

// tip: 藏品卡片组件
export const Collectible: React.FC<CollectibleProps> = memo(props => {
    // props
    const { allowShare, allowStar, belts, from, sendDate, starDate, takeDate, to, content, workTagId, author, book, count, total, sign, price, id, allowLink } = props

    // redux
    const { workTags } = useAppSelector((state: RootState) => state.workReducer)

    // other data
    const color = workTags.find(workTag => workTag.id === workTagId)?.color || "#102e24"
    const coverUrl = workTags.find(workTag => workTag.id === workTagId)?.coverUrl || ""

    let fontSize: number | string = 0
    let lineHeight: number | string = 0
    if(content.length > 60) { fontSize = 14; lineHeight = 26; }
    else if(content.length > 40 && content.length <= 60) { fontSize = 16; lineHeight = 28 }
    else if(content.length > 30 && content.length <= 40) { fontSize = 18; lineHeight = 30 }
    else if(content.length > 10 && content.length <= 30) { fontSize = 20; lineHeight = 32 }
    else if(content.length > 5 && content.length <= 10) { fontSize = 24; lineHeight = 34 }
    else if(content.length <= 5) { fontSize = 28; lineHeight = 34 }
    fontSize = (fontSize / 16) + "rem"
    lineHeight = (lineHeight / 16) + "rem"

    const collectibleRef = useRef<HTMLElement | null>(null);
    const [beltsWidth, setBeltsWidth] = useState<string>("219")
    const [beltsHeight, setBeltsHeight] = useState<string>("40")

    useEffect(() => {
        const current = collectibleRef.current
        // 监听 Collectible 宽度变化动态更新 belts 的宽高
        const resizeObserver  = new ResizeObserver(entries  => {
            entries.forEach(entry  => {
                const width = entry.contentRect.width * 0.8
                const height = width * (40 / 219)
                setBeltsWidth(width.toString())
                setBeltsHeight(height.toString())
            })
        })
        current && resizeObserver.observe(current)
        return () => {
            current && resizeObserver.unobserve(current)
        }
    }, [])
    
    return (
        // 卡片宽度呈响应式变化（卡片纵横比例：319 * 418）
        <main ref={collectibleRef} style={{ marginTop: belts ? beltsHeight + "px":"0" }} className='collectible 2xl:w-[320px] w-[260px] relative select-none'>
            <div 
                style={{ borderColor: color }}
                className='w-full h-full border-solid border-2 rounded-2xl p-[calc(16/319*100%)] flex flex-col bg-white'
            >
                <section style={{ borderColor: color }} className='w-full aspect-square rounded-lg border-solid border-[1px] overflow-hidden relative'>
                    {/* 背景 */}
                    <div style={{ backgroundColor: color }} className='w-full h-full absolute top-0 left-0 z-10 opacity-20'>
                        { coverUrl && <img src={coverUrl} alt="背景" className='h-full object-cover'/> }
                    </div>
                    {/* 背景花纹 */}
                    <PatternOneSVG color={color} />
                    {/* 语录内容 */}
                    <div 
                        style={{ fontSize, lineHeight, color }}
                        className='source-han-serif-hk absolute z-50 w-full h-full flex justify-center items-center px-10 bg-transparent'
                    >{content}</div>
                    {/* 跳转遮罩层 */}
                    {
                        allowLink 
                        ? (
                            <ScrollToTopLink to={`/home/collectible/${id}`}> 
                                <Row className='absolute top-0 z-50 left-0 w-full h-full bg-transparent'></Row>
                            </ScrollToTopLink>
                        )
                        : <Row className='absolute top-0 z-50 left-0 w-full h-full bg-transparent'></Row>
                    }
                    {/* 右上角收藏 & 右上角分享 */}
                    {
                        allowStar && allowShare
                        && (
                            <div className='absolute top-2 right-2 flex gap-1 cursor-pointer z-20'>
                                <StarFormatSVG color={color}/>
                                <ShareModal>
                                    <ShareFormatSVG color={color}/>
                                </ShareModal>
                            </div>
                        )
                    }
                </section>
                <section className='flex flex-col justify-between w-full flex-1 pt-4'>
                    <div className='w-full h-full flex justify-between items-start gap-2'>
                        {/* 书名 & 作者 */}
                        <div className='flex flex-col w-auto overflow-hidden'>
                            <Popover placement={'top'} content={book}>
                                <div className='w-full font-medium text-base text-primary text-ellipsis overflow-hidden text-nowrap'>{book}</div>
                            </Popover>
                            <span className='font-medium text-xs text-primary flex items-center gap-1'>
                                {author}
                                <AuthorSVG size={"14"}/>
                            </span>
                        </div>
                        {/* 稀有度 | 签名 */}
                        <div className='text-xs relative w-24 h-full'>
                            {
                                (typeof count === "number" && typeof total === "number")
                                ?(
                                    <span className='flex gap-1 items-center text-primary absolute top-0 right-0'>
                                        <DiamondSVG size={"12"}/>
                                        {count}/{total}
                                    </span>
                                )
                                :sign
                                ?(
                                    <section className='w-full h-12'>
                                        <img src={sign} alt="签名" className='w-full h-full'/>
                                    </section>
                                )
                                :null
                            }
                        </div>
                    </div>
                    {/* 价格 | 收礼 | 送礼 | 收礼(收藏框) | 送礼(收藏框) | 收藏 */}
                    <div className='flex justify-between text-sm'>
                        <span>
                            {
                                (typeof price === 'number' && !isNaN(price)) 
                                ?(<span className='text-sm font-medium text-primary'>價格</span>)
                                :to?(<>To：{to}</>)
                                :sendDate?(<>送禮日期：{new Date(sendDate).toLocaleDateString()}</>)
                                :takeDate?(<>收禮日期：{new Date(takeDate).toLocaleDateString()}</>)
                                :starDate?(<>收藏日期：{new Date(starDate).toLocaleDateString()}</>)
                                :null
                            }
                        </span>
                        <span>
                            {
                                (typeof price === 'number' && !isNaN(price))
                                ?(<span className='text-sm font-medium text-primary'>HKD {price}</span>)
                                :from?(<>From：{from}</>)
                                :null
                            }
                        </span>
                    </div>
                </section>
            </div>
            {/* 顶部礼物带装饰 */}
            {
                belts && (
                    <aside className='absolute top-[2px] left-1/2 -translate-x-1/2 -translate-y-full flex items-end'>
                        <GiftBeltsSVG color={belts} width={beltsWidth} height={beltsHeight}/>
                    </aside>
                )
            }
        </main>
    )
})