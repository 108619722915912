import React, { memo, useContext, useState } from 'react'
import SearchSVG from '../../assets/svg/search-light.svg'
import SearchDarkSVG from '../../assets/svg/search-dark.svg'
import { HeaderCustomContext } from '.'
import { useLanguage } from '../../hooks/useLanguage'
import { ScrollToTopLink } from '../base/ScrollToTopLink'
import { useNavigate } from 'react-router-dom'
import { IoSearchOutline } from "react-icons/io5";

interface SearchProps {
  fullWidth?: boolean;
  fontColor?: string;
  onSearch?: (keywords: string) => void; // New prop for search function
}

const Search: React.FC<SearchProps> = memo(({ fullWidth, fontColor, onSearch }) => {
  // router
  const navigate = useNavigate()

  // context
  const { isTransparent } = useContext(HeaderCustomContext)

  // hook
  const { t } = useLanguage()

  // responsive data
  const [keywords, setKeywords] = useState<string>("")

  // other data
  const placeholderColor = isTransparent ? "placeholder-[#C9CFC1]":"placeholder-[#B6AEAC]"

  // method: 处理搜索框输入
  const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    setKeywords((e.target as any).value)
  }

  // method: 处理搜索框回车
  const handleKeyup = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if(e.code === 'Enter') {
      if (onSearch) {
        onSearch(keywords)
      } 
      navigate(`/home/search?keywords=${(e.target as any).value}`)
      setKeywords("")
    }
  }

  // method: 处理搜索图标点击
  const handleSearchClick = () => {
    if (onSearch) {
      onSearch(keywords)
    } else {
      navigate(`/home/search?keywords=${keywords}`)
    }
    setKeywords("")
  }

  return (
    <section className='relative z-50'>
      <input
        type="text"
        value={keywords}
        placeholder={t()['header']['search']}
        style={{
          ["--tw-bg-opacity" as any]: isTransparent ? '.1' : '.2',
          color: fontColor || (isTransparent ? '#fff' : "#000") // Use fontColor prop if provided
        }}
        className={[
          'transition duration-500 border-0 outline-none pl-14 pr-[24px] py-[11px] rounded-[10px] text-sm bg-[#B6AEAC]', 
          placeholderColor, 
          fullWidth ? 'w-full' : 'w-52 xl:w-64 2xl:w-80'
        ].join(" ")}
        onInput={handleInput}
        onKeyUp={handleKeyup}
      />
      <ScrollToTopLink 
        to={`/home/search?keywords=${keywords}`} 
        callback={() => setKeywords("")}
      >
        <div 
          className='absolute top-1/2 left-5 -translate-y-1/2 cursor-pointer flex al'
          onClick={handleSearchClick} // Add onClick handler
        >
        {isTransparent?
          <IoSearchOutline 
          />
          :
          <IoSearchOutline/>
        }
        </div>
          
      </ScrollToTopLink>
    </section>
  )
})

export default Search