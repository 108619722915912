import { Input } from "antd"
import { memo, useContext } from "react"
import MyButton from "../../../components/my/MyButton"
import { WorkDetailForm } from "../../../pages/work/detail"
import { MyTabsContext } from "../../../components/my/MyTabs"

interface WorkInfoDetailProps {
    form: WorkDetailForm
    setForm: React.Dispatch<React.SetStateAction<WorkDetailForm>>
}

export const WorkInfoDetail: React.FC<WorkInfoDetailProps> = memo(({ form, setForm }) => {
    // context
    const { handleNextStep } = useContext(MyTabsContext)

    return (
        <main className='w-full flex flex-col px-5 justify-between gap-10'>
            <div className='flex flex-col gap-4'>
                <p className='text-base text-primary m-0 p-0'>書本名稱</p>
                <Input 
                    name='title'
                    value={form.title}
                    className="h-14 text-sm px-4"
                    onChange={e => setForm(state => ({ ...state, title: e.target.value }))}
                ></Input>
                <p className='text-base text-primary m-0 p-0'>作品簡介 <span className="text-gray-500">（上限 500 字）</span></p>
                <Input.TextArea 
                    name='description'
                    className='text-sm' 
                    value={form.description}
                    onChange={e => setForm(state => ({ ...state, description: e.target.value }))}
                    rows={6} 
                ></Input.TextArea>
            </div>
            <MyButton 
                type='primary' 
                onClick={() => handleNextStep()}
                className='h-12 text-base'
            >繼續</MyButton>
        </main>
    )
})