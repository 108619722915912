import { Input, Modal, Button } from 'antd'
import React, { memo, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AntdProviderContext } from '../../../../providers/AntdProvider'

interface ShareModalProps {
    children: string | JSX.Element
}

const ShareModal: React.FC<ShareModalProps> = memo(({ children }) => {
    // router
    const { pathname } = useLocation()

    // responsive data
    const [isModalShow, setIsModalShow] = useState(false)
    const [text, setText] = useState('https://www.thecatnip.com' + pathname)

    // context
    const { messageApi } = useContext(AntdProviderContext)

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(text)
            messageApi.success('複製成功')
        }
        catch(error) {
            messageApi.error('複製失敗')
        }
    }

    return (
        <>
            <section onClick={() => setIsModalShow(true)}>
                {children}
            </section>
            <Modal
                open={isModalShow}
                footer={null}
                onCancel={() => setIsModalShow(false)} 
                centered={true}
                style={{ minWidth: '800px' }}
            >
                {/* 模态框-分享链接 */}
                <section className='px-20 py-16 flex justify-center gap-5 flex-col items-center bg-white rounded-xl'>
                    <b className='text-xl'>複製以下連結並發送出去</b>
                    <div className='w-full flex justify-between relative'>
                        <Input 
                            value={text}
                            onChange={(e) => setText(e.target.value)} 
                            className='h-14 pr-20 font-bold text-sm' 
                        />
                        <Button 
                            onClick={handleCopy} 
                            type='primary' 
                            className='h-1/2 absolute top-1/2 right-4 -translate-y-1/2 text-sm'
                        >複製</Button>
                    </div>
                </section>
            </Modal>
        </>
    )
})

export default ShareModal