import { WorkVo } from "../../vo/work.vo"
import { http } from "../request"

export const workFindMineApi = async (accessToken: string) => {
    return await http.get<WorkVo[]>("/work/my-works", {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
}
