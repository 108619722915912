import { Modal } from 'antd'
import React, { createContext, memo, useContext, useRef, useState } from 'react'
import MyTabs, { MyTabsRef } from '../../../my/MyTabs'
import { EditContext } from '../../../../pages/work/edit'
import { CollectibleSign } from './sign'
import { CollectibleInfo } from './info'
import { CollectibleStyle } from './style'
import { RootState, useAppSelector } from '../../../../redux'
import { CollectibleProps } from '../../../../vo/collectible.vo'
import { CollectibleCreateBody, collectibleCreateApi } from '../../../../api/collectible/create'
import { Collectible } from '../../../collectible/Collectible'
import { BindStripeModal } from '../../user/BindStripeModal'
import useAccessToken from '../../../../hooks/useAccessToken'
import { AntdProviderContext } from '../../../../providers/AntdProvider'
import { ViewportProviderContext } from '../../../../providers/ViewportProvider'

interface CollectibleFormModalProps {
    children: string | JSX.Element
    className?: string
    createQuote: () => void
}

export interface CollectibleFormContextType {
    price: string
    setPrice: React.Dispatch<React.SetStateAction<string>>
    count: string
    setCount: React.Dispatch<React.SetStateAction<string>>
    mainTagId: number | null
    setMainTagId: React.Dispatch<React.SetStateAction<number | null>>
    signUrl: string
    setSignUrl: React.Dispatch<React.SetStateAction<string>>
    handleSubmit: () => void
}

export const CollectibleFormContext = createContext<CollectibleFormContextType | undefined>(undefined)

export const CollectibleFormModal: React.FC<CollectibleFormModalProps> = memo(({ children, className, createQuote }) => {
    // ref
    const childRef = useRef<MyTabsRef>(null)

    // context
    const { selectionText, selection } = useContext(EditContext)
    const { messageApi } = useContext(AntdProviderContext)
    const { isMobile, isDesktop, notDesktop } = useContext(ViewportProviderContext);


    // redux
    const { editWork, editChapter } = useAppSelector((state: RootState) => state.editReducer)
    const { user } = useAppSelector((state: RootState) => state.authReducer)

    // hook
    const { accessToken } = useAccessToken()

    // responsive data
    const [isModalShow, setIsModalShow] = useState<boolean>(false)
    const [isBindStripeModalShow, setIsBindStripeModalShow] = useState<boolean>(false)

    // form data
    const [price, setPrice] = useState<string>("")
    const [count, setCount] = useState<string>("1")
    const [mainTagId, setMainTagId] = useState<number | null>(null)
    const [signUrl, setSignUrl] = useState<string>("")

    // other data
    const collectible: CollectibleProps = {
        id: 0,
        author: user?.username || "",
        book: editWork?.title || "",
        content: selectionText,
        workTagId: mainTagId || 0,
        workTypeId: editWork?.work_type_id || 0,
        price: price.length ? Number(price) : 0,
        total: count.length ? Number(count) : 0,
        count: count.length ? Number(count) : 0,
        chapter_id: 1,
        is_shelf: false,
        allowLink: false
    }

    // method: 创建藏品
    const handleSubmit = () => {
        // validate
        if(!editChapter) return messageApi.warning("當前沒有正在編輯的章節")
        if(price.length === 0) return messageApi.warning("藏品價格不允許爲空")
        if(Number(price) < 4) return messageApi.warning("藏品價格不允許低於 4 HKD")
        if(count.length === 0) return messageApi.warning("藏品數量不允許爲空")
        if(mainTagId === null) return messageApi.warning("藏品標籤不允許爲空")
        // main
        const { anchor, focus } = selection
        const paragraphIndex = anchor.path[0]
        const contentIndex = anchor.path[1]
        const textPosition = { 
            start: anchor.offset < focus.offset ? anchor.offset : focus.offset,
            end: anchor.offset > focus.offset ? anchor.offset : focus.offset
        }
        let body: CollectibleCreateBody = {
            chapterId: editChapter?.id,
            contentIndex,
            paragraphIndex,
            textPosition,
            content: selectionText,
            amountCents: Number(price) * 100,
            collectibleQuantity: Number(count),
            fontId: 0,
            tagId: mainTagId,
            sign: signUrl,
        }
        collectibleCreateApi(body, accessToken).then(res => {
            // validate stripe account
            if((res as any).error === 'collectible-stripe210f035') {
                return setIsBindStripeModalShow(true)
            } else if((res as any).error) {
                return setIsModalShow(false)
            } else {
                createQuote() // 感官上创建藏品
                setPrice("")
                setCount("")
                setMainTagId(editWork?.main_tag_id || 0)
                setSignUrl("")
                setIsModalShow(false)
            }
        })
    }

    // other data
    const tabs = [
        { id: "1", name: "藏品資訊", element: <CollectibleInfo childRef={childRef} /> },
        { id: "2", name: "藏品樣式", element: <CollectibleStyle childRef={childRef} /> },
        { id: "3", name: "作者簽名", element: <CollectibleSign /> }
    ]

    // method: 尝试打开藏品创建模态框
    const tryToOpenModal = () => {
        console.log('-----', selectionText.includes('它就'))
        if(editChapter?.status !== 'VERIFIED') {
            setIsModalShow(false)
            messageApi.warning("章節發佈後即可發佈藏品哦~")
        } 
        else if(selectionText.length === 0) {
            setIsModalShow(false)
            messageApi.warning("不允許將空文本作爲藏品發佈")
        } 
        else if(selectionText.length >= 52) {
            setIsModalShow(false)
            messageApi.warning("請保持藏品字數在 52 個子元以內")
        } 
        else {
            setIsModalShow(true)
        }
    }

    return (
        <>
            <section className={className} onClick={tryToOpenModal}>
                {children}
            </section>
            <BindStripeModal 
                isModalShow={isBindStripeModalShow} 
                setIsModalShow={setIsBindStripeModalShow}
            />
            <Modal
                open={isModalShow}
                footer={null}
                onCancel={() => setIsModalShow(false)} 
                centered={true}
                width={'60rem'}
                style={{ minWidth: '140px' }}
            >
                {/* 模态框-發佈收藏品 */}
                <article className={` bg-white rounded-xl p-[10%]`}>
                    <header className='text-2xl'>發佈收藏品</header>
                    <section className='flex flex-col gap-8'> 
                        <CollectibleFormContext.Provider value={{ price, setPrice, count, setCount, mainTagId, setMainTagId, signUrl, setSignUrl, handleSubmit }}>
                            <MyTabs 
                                ref={childRef}
                                tabs={tabs} 
                                leftSlot={
                                    <Collectible {...collectible}/>
                                } 
                            />
                        </CollectibleFormContext.Provider>
                    </section>
                    <footer>
                        <p className='pt-8 m-0 text-sm'>*注意項目: 藏品一經發佈則無法修改其內容，請反覆核對再發佈藏品</p>
                    </footer>
                </article>
            </Modal>
        </>
    )
})