import React, { memo, useState } from 'react'

interface MyPopoverProps {
    children: JSX.Element
    content: string
}

const MyPopover: React.FC<MyPopoverProps> = memo(({ children, content }) => {
    // responsive data
    const [visible, setVisible] = useState(false)

    return (
        <main 
            className='relative flex items-center' 
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}
        >
            { children }
            <section 
                style={{ opacity: visible ? 1 : 0 }}
                className='absolute left-1/2 bottom-0 translate-y-full -translate-x-1/2 -z-50 px-[10px] py-[5.5px] bg-[#333333] rounded-[5px] text-[10px] text-white transition duration-300'
            >
                <div style={{ width: `${content.length}em` }}>{ content }</div>
                <span className='border-[5px] border-b-10 border-solid border-transparent border-b-[#333333] bg-transparent absolute top-0 left-1/2 -translate-x-1/2 -translate-y-full'></span>
            </section>
        </main>
    )
})

export default MyPopover