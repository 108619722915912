import React from 'react';
import MyImage from './MyImage';

interface MyAvatarProps {
  src?: string
  size?: number
  className?: string
  style?: React.CSSProperties
}

const MyAvatar: React.FC<MyAvatarProps> = ({ src = "", size = 10, className, style }) => {
  // other data
  const insteadUrl = "https://catnip-public.s3.ap-east-1.amazonaws.com/DefaultAvatar.png"
  
  return (
    <MyImage 
      src={src} 
      style={style}
      subSrc={insteadUrl}
      className={[`w-${size} aspect-square object-cover cursor-pointer rounded-full`, className].join(' ')}
    />
  )
}

export default MyAvatar;