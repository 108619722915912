import { http } from "../request"

export interface MoodProps {
    id: number,
    light: string,
    dark: string,
    border: string,
    text: string,
    backgroundUrl: string
}

export const moodFindAllApi = async () => {
    return await http.get<MoodProps[]>("/mood")
}
