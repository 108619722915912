import { http } from "../request"

export interface WorkTagProps {
    id: number
    name: string
    color: string
    created_at: string
    updated_at: string
    status: 'ACTIVE' | 'DELETED'
    coverUrl: string
    pattern_id: number
}

export const workTagFindAllApi = async () => {
    return await http.get<WorkTagProps[]>("/work-tag/findAll")
}
