import { CollectibleVo } from "../../vo/collectible.vo"
import { http } from "../request"

export interface CollectibleCreateBody {
    chapterId: number
    paragraphIndex: number,
    contentIndex: number,
    textPosition: {
        start: number,
        end: number
    },
    content: string,
    amountCents: number,
    collectibleQuantity: number,
    fontId: number,
    tagId: number,
    sign: string
}

export const collectibleCreateApi = async (body: CollectibleCreateBody, accessToken: string) => {
    return await http.post<CollectibleVo>("/collectible", body, {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
}
