import React, { memo } from 'react'

interface BannerProps {
    title: string
}

// tip: 横幅组件
const Banner: React.FC<BannerProps> = memo((props) => {
    const { title } = props
    return (
        <main className='w-full text-lg font-semibold h-16 bg-[#102E24] text-[#FFFFFF] flex justify-center items-center'>{title}</main>
    )
})

export default Banner