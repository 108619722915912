import React, { memo, useContext, useEffect, useState } from 'react'
import { collectibleFindApi } from '../../../api/collectible/find'
import { useNavigate, useParams } from 'react-router-dom'
import useAccessToken from '../../../hooks/useAccessToken'
import { CollectibleProps } from '../../../vo/collectible.vo'
import { parseStar } from '../../../utils/parseCollectibles'
import { ScrollToTopLink } from '../../../components/base/ScrollToTopLink'
import { Button } from 'antd'
import { Collectible } from '../../../components/collectible/Collectible'
import { ViewportProviderContext } from '../../../providers/ViewportProvider'

export const CollectibleSuccess: React.FC = memo(() => {
    // router
    const navigate = useNavigate()
    const params = useParams() as { id: string }
    const id = parseInt(params.id)

    // context
    const { notDesktop } = useContext(ViewportProviderContext)

    // hooks
    const { accessToken } = useAccessToken()

    // responsive data
    const [collectible, setCollectible] = useState<CollectibleProps | null>(null)

    // mounted: fetch data
    useEffect(() => {
        async function fetchData() {
            const res = await collectibleFindApi({ id }, accessToken)
            const collectible = res.find(collectible => collectible.id === id)
            if(collectible) {
                setCollectible(parseStar([collectible])[0])
            }
        }
        fetchData()
    }, [accessToken, id])

    return (
        <main className={[
            'flex flex-col overflow-hidden bg-white',
            notDesktop ? '' : 'rounded-xl'
        ].join(' ')}>
            <div className='w-full flex-1 py-14 flex justify-center'>
                <section className={[
                    notDesktop ? '' : 'w-[19rem]'
                ].join(' ')}>
                    { collectible && <Collectible {...collectible}/> }
                </section>
            </div>
            <footer className='w-full py-8 bg-[#102e24] flex flex-col justify-center items-center gap-4 px-10'>
                <span className='text-white text-2xl'>已成功收藏藏品！</span>
                {
                    notDesktop
                    ? (
                        <Button 
                            onClick={() => navigate('/star')}
                            className='w-full h-12 text-base text-white bg-white bg-opacity-10 border-0' size='large'
                        >立即查看收藏</Button>
                    )
                    : (
                        <ScrollToTopLink to='/star'>
                            <Button 
                                className='h-12 text-base text-white bg-white bg-opacity-10 border-0' size='large'
                            >立即查看收藏</Button>
                        </ScrollToTopLink>
                    )
                }
            </footer>
        </main>
    )
})
