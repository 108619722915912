import { http } from "../request"

interface AccountCreateConnectResponse {
    url: string
}

export const accountCreateConnectApi = async (accessToken: string) => {
    return await http.post<AccountCreateConnectResponse>("/account/createConnectAccount", {}, {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
}