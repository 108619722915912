import { Select, Slider } from "antd"
import { memo, useMemo } from "react"
import MyButton from "../../../components/my/MyButton"
import MyTag from "../../../components/my/MyTag"
import { useAppSelector, RootState } from "../../../redux"
import { WorkDetailForm } from "../../../pages/work/detail"

interface WorkCategoryDetailProps {
    form: WorkDetailForm
    setForm: React.Dispatch<React.SetStateAction<WorkDetailForm>>
    submitTitle: string
    handleSubmit: Function
}

export const WorkCategoryDetail: React.FC<WorkCategoryDetailProps> = memo(({ form, setForm, submitTitle, handleSubmit }) => {
    // redux
    const { workTypes, workTags } = useAppSelector((state: RootState) => state.workReducer)

    // other data
    const workTypeOptions = workTypes.map(workType => ({
        value: workType.id.toString(),
        label: <section className='py-2 text-base'>{workType.name}</section>
    }))
    const mainTagOptions = workTags.map(workTag => ({
        value: workTag.id.toString(),
        label: (
            <section className="w-12 h-10 py-2">
                <MyTag color={workTag.color} text={workTag.name}/>
            </section>
        )
    }))

    // computed: 次要标签选项中不包含已选择的主标签
    const subTagOptions = useMemo(() => {
        return mainTagOptions.filter(option => option.value !== form.mainTagId?.toString())
    }, [mainTagOptions, form.mainTagId])

    // method: change work tag
    const changeWorkTag = (value: string) => {
        const insteadCover = workTags.find(workTag => workTag.id === Number(value))
        if(!form.cover && insteadCover) {
            setForm(state => ({ ...state, subCover: insteadCover.coverUrl }))
        }
        setForm(state => ({ ...state, mainTagId: Number(value) }))
    }

    return (
        <main className='w-full flex flex-col flex-[5] px-5 gap-10 justify-between'>
            <section className='flex flex-col gap-4'>
                <div className='text-base'>書籍類別</div>
                <Select
                    value={form.workTypeId?.toString()}
                    onChange={(value: string) => setForm(state => ({ ...state, workTypeId: Number(value) }))}
                    options={workTypeOptions}
                    className='w-full h-14'
                ></Select>
                <div className='text-base'>主類別標籤</div>
                <Select
                    value={form.mainTagId?.toString()}
                    onChange={changeWorkTag}
                    showSearch={false}
                    options={mainTagOptions} 
                    className='w-full h-14'
                ></Select>
                <div className='text-base'>類別標籤</div>
                <Select
                    mode='tags'
                    value={form.subTagIds.map(id => id.toString())}
                    onChange={(value: string[]) => setForm(state => ({ ...state, subTagIds: value.map(id => Number(id)) }))}
                    showSearch={false}
                    maxTagCount={4}
                    options={subTagOptions} 
                    className='w-full h-14'
                ></Select>
                <div className='text-base'>編輯完成度</div>
                <div className='flex items-center gap-5'>
                    <div className='w-full h-full pl-3'>
                        <Slider 
                            min={0} 
                            max={100} 
                            value={form.progress}
                            onChange={(value) => setForm(state => ({ ...state, progress: value }))} 
                            tooltip={{ open: false }}
                            defaultValue={0}
                            style={{ margin: 0 }}
                        />
                    </div>
                    <div className='w-16 h-8 flex justify-center items-center bg-[#ebeee8] rounded-lg text-base'>
                        { form.progress }%
                    </div>
                </div>
            </section>
            <section>
                <MyButton 
                    type='primary' 
                    onClick={() => handleSubmit()} 
                    className='w-full h-12 text-base'
                >{ submitTitle }</MyButton>
            </section>
        </main>
    )
})