import React, { Fragment, memo, useContext, useMemo, useState } from 'react'
import { Collectible } from '../../components/collectible/Collectible'
import { Empty, Pagination, Spin } from 'antd'
import { CollectibleProps } from '../../vo/collectible.vo'
import NavBar from '../base/NavBar'
import { ViewportProviderContext } from '../../providers/ViewportProvider'

interface CollectibleListProps {
    data: CollectibleProps[]
    loading: boolean
    headerSlot?: JSX.Element
}

export const CollectibleList: React.FC<CollectibleListProps> = memo(({ data, loading, headerSlot }) => {
    // responsive data
    const pageSize = 9
    const [total, setTotal] = useState<number>(1)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [workTagId, setWorkTagId] = useState<number | "all">("all")
    const [workTypeId, setWorkTypeId] = useState<number | "all">("all")

    // context
    const { notDesktop } = useContext(ViewportProviderContext)

    // computed: 计算当前页数据
    const computed = useMemo(() => {
        const startIndex = (currentPage - 1) * pageSize
        const endIndex = startIndex + pageSize
        const filtered = data.filter(item => {
            return (item.workTagId === workTagId || workTagId === "all")
            && (item.workTypeId === workTypeId || workTypeId === "all")
        })
        setTotal(filtered.length)
        return filtered.slice(startIndex, endIndex)
    }, [data, currentPage, workTypeId, workTagId])

    // method: 处理换页
    const handelChangePage = (page: number, _pageSize: number) => {
        setCurrentPage(page)
    }

    return (
        <main>
            <header
                className={[
                    notDesktop
                    ? 'w-full'
                    : 'mx-auto min-w-[960px] flex flex-col gap-6'
                ].join(' ')}
            >
                <NavBar
                    setCurrentPage={setCurrentPage}
                    changeWorkTypeId={id => setWorkTypeId(id)}
                    changeWorkTagId={id => setWorkTagId(id)}
                />
                <section
                    className={[
                        notDesktop
                        ? 'text-center py-5'
                        : 'flex mx-auto w-3/5 min-w-[960px]'
                    ].join(' ')}
                >
                    { headerSlot }
                </section>
            </header>
            {
                loading
                ? <Spin className='w-full py-20'/>
                : (computed.length > 0)
                ? (
                    <Fragment>
                        <div className='flex justify-center'>
                            <article
                                className={[
                                    'grid gap-y-12',
                                    notDesktop
                                    ? 'grid-cols-1'
                                    : 'grid-cols-3 gap-x-6 py-12'
                                ].join(' ')}
                            >
                                { computed.map(collectible => <Collectible key={collectible.id} {...collectible}/>) }
                            </article>
                        </div>
                        <section className='w-full flex justify-center mb-20 py-5'>
                            <Pagination 
                                defaultCurrent={1}
                                total={total}
                                current={currentPage}
                                pageSize={pageSize} 
                                showSizeChanger={false}
                                onChange={handelChangePage}
                                hideOnSinglePage
                            />
                        </section>
                    </Fragment>
                )
                : <Empty className='mx-auto py-20' description="沒有符合條件的藏品"/>
            }
        </main>
    )
})