import { CollectibleVo } from "../../vo/collectible.vo"
import { http } from "../request"

export interface CollectibleFindParams {
    id?: number
    authors_id?: number
    content?: string
    work_id?: number
    chapter_id?: number
    priceSort?: "from_high" | "from_low"
}

export const collectibleFindApi = async (params: CollectibleFindParams, accessToken: string) => {
    // 将 params 由 JavaScript 对象格式转换为 query 格式
    const queryString = Object.keys(params)
        .filter(key => (params as any)[key] !== undefined)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent((params as any)[key])}`)
        .join('&')
    return accessToken 
    ? await http.get<CollectibleVo[]>(`/collectible?${queryString}`, {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
    : await http.get<CollectibleVo[]>(`/collectible?${queryString}`)
}
