import { http } from "../request"

export interface WorkTypeProps {
    id: number
    name: string
    created_at: string
    updated_at: string
    status: 'ACTIVE' | 'DELETED'
}

export const workTypeFindAllApi = async () => {
    return await http.get<WorkTypeProps[]>("/work-type/findAll")
}
