import React, { memo, useCallback } from 'react'
import { RootState, useAppDispatch, useAppSelector } from '../../redux'
import { MoodTheme } from '../../redux/featrue/themeSlice'
import useAccessToken from '../../hooks/useAccessToken'
import { accountUpdateAsync } from '../../redux/featrue/authSlice'

const Palette: React.FC = memo(() => {
    // redux
    const dispatch = useAppDispatch()
    const { user } = useAppSelector((state: RootState) => state.authReducer)
    const { moods } = useAppSelector((state: RootState) => state.themeReducer)

    // other data
    const currentMood = moods.find(mood => mood.id === user?.mood_id)

    // hook
    const { accessToken } = useAccessToken()

    // method: 切换心情
    const handleChangeTheme = useCallback((mood: MoodTheme) => {
        dispatch(accountUpdateAsync({
            body: { mood_id: mood.id },
            accessToken
        }))
    }, [accessToken, dispatch])

    return (
        <>
            <p className='m-0 p-0 text-primary select-none'>選一隻色代表你今天的心情</p>
            <ul className='flex gap-2'>
            {
                moods.map(mood => (
                    <li 
                        key={mood.id} 
                        onClick={() => handleChangeTheme(mood)}
                        style={{ borderColor: mood.light === currentMood?.light ? "#298eeb":"transparent" }} 
                        className='w-10 h-10 rounded-full cursor-pointer border-solid border-[1px] p-[2px] transition duration-300'
                    >
                        <span
                            style={{ backgroundColor: mood.light }} 
                            className='w-full h-full inline-block rounded-full'
                        ></span>
                    </li>
                ))
            }
            </ul>
        </>
    )
})

export default Palette