import React, { memo } from 'react'

interface GistBeltsSVG {
    color?: string
    width?: string
    height?: string
}

const GiftBeltsSVG: React.FC<GistBeltsSVG> = memo(({ color = "white", width = "219", height = "40" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 219 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_504_23585)">
<path d="M121.351 28.3713C121.351 28.3713 127.2 16.3032 138.907 9.5473C151.625 2.21401 160.004 0.664593 162.524 0.491366C165.044 0.318139 166.122 3.36886 167.738 9.12386C169.354 14.8788 169.421 34.8096 160.485 38.9767H118.619L121.351 28.3713Z" stroke={color} strokeWidth="1.61129" strokeMiterlimit="10"/>
<path d="M162.418 2.94689C161.206 2.52344 136.175 9.92409 126.662 24.5041C126.662 24.5041 131.212 25.3317 149.807 13.0903C156.637 8.59602 162.418 2.94689 162.418 2.94689Z" stroke={color} strokeWidth="1.61129" strokeMiterlimit="10"/>
<path d="M166.16 32.1338C183.274 27.0332 195.664 18.7857 206.524 7.82422C206.524 7.82422 201.705 20.6142 197.463 23.3184C197.463 23.3184 209.439 25.272 216.077 23.3184C216.077 23.3184 201.08 34.0393 160.561 38.9955" stroke={color} strokeWidth="1.61129" strokeMiterlimit="10"/>
<path d="M97.6485 28.3713C97.6485 28.3713 91.7997 16.3032 80.0925 9.5473C67.3752 2.21401 58.9868 0.664593 56.4761 0.491366C53.9654 0.318139 52.8783 3.36886 51.2622 9.12386C49.6461 14.8788 49.5788 34.8096 58.5155 38.9767H100.381L97.6485 28.3713Z" stroke={color} strokeWidth="1.61129" strokeMiterlimit="10"/>
<path d="M56.5915 2.94689C57.8036 2.52344 82.8341 9.92409 92.348 24.5041C92.348 24.5041 87.7979 25.3317 69.203 13.0903C62.373 8.59602 56.5915 2.94689 56.5915 2.94689Z" stroke={color} strokeWidth="1.61129" strokeMiterlimit="10"/>
<path d="M52.8399 32.1338C35.7264 27.0332 23.3362 18.7857 12.4755 7.82422C12.4755 7.82422 17.295 20.6142 21.5373 23.3184C21.5373 23.3184 9.56074 25.272 2.92313 23.3184C2.92313 23.3184 17.9203 34.0393 58.4385 38.9955" stroke={color} strokeWidth="1.61129" strokeMiterlimit="10"/>
<path d="M98.7452 32.2984C98.4181 30.5276 98.4277 28.7183 98.6297 26.9379C98.649 26.7676 98.701 26.3829 98.7548 26.2065C98.7933 26.0333 98.8606 25.8793 98.9472 25.7253C99.9476 24.0027 102.853 24.7726 104.44 24.8688C107.961 25.0902 111.462 24.4165 114.916 23.7044C116.647 23.3483 118.389 22.9826 120.159 22.9345C120.515 22.9248 120.88 22.9345 121.217 23.0499C122.564 23.5119 121.909 25.5329 121.707 26.5434C120.688 30.7393 119.543 34.8775 118.206 38.9772H114.618H107.874H104.45H100.39C100.39 38.9772 100.034 37.7646 99.9188 37.2065C99.4378 34.8198 99.0722 34.0691 98.7452 32.2984Z" stroke={color} strokeWidth="1.61129" strokeMiterlimit="10"/>
</g>
<defs>
<clipPath id="clip0_504_23585">
<rect width="218.329" height="39.4766" fill="white" transform="translate(0.335426)"/>
</clipPath>
</defs>
</svg>
  )
})

export default GiftBeltsSVG