import React, { memo, useContext, useEffect, useState } from 'react'
import { UserContext } from '../../pages/user'
import { dynamicFindApi } from '../../api/dynamic/find'
import { Dynamic } from '../../components/community/Dynamic'
import { DynamicVo } from '../../vo/dynamic.vo'
import { Empty, Spin } from 'antd'
import { ViewportProviderContext } from '../../providers/ViewportProvider'

const UserDynamic: React.FC = memo(() => {
  // context
  const { account } = useContext(UserContext)
  const { notDesktop } = useContext(ViewportProviderContext)

  // responsive data
  const [dynamics, setDynamics] = useState<DynamicVo[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  // mounted: 发送请求获取动态数据
  useEffect(() => {
    async function fetchDynamics() {
      setLoading(true)
      if(account) {
        const res = await dynamicFindApi(account.id)
        if(!(res as any).error) {
          setDynamics(res)
          setLoading(false)
        }
      }
    }
    fetchDynamics()
  }, [account])

  return (
    <main className={[
      'flex flex-col gap-6',
      notDesktop ? '' : 'w-3/5 min-w-[960px] mx-auto p-10'
    ].join(' ')}>
      {
        loading
        ? <Spin className='w-full py-20' />
        : (dynamics.length > 0)
          ? dynamics.map(dynamic => (
            <Dynamic key={dynamic.id} data={dynamic}/>
          ))
          : <Empty className='mx-auto py-10 mb-20'/>
      }
    </main>
  )
})

export default UserDynamic