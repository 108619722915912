import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ScrollToTopLink } from '../../components/base/ScrollToTopLink';
import LeftSVG from '../../components/svgs/LeftSVG';
import { ViewportProviderContext } from '../../providers/ViewportProvider';
import BackButton from '../buttons/backButton';

interface EditTitleBarProps {
    editWorkTitle: string | undefined;
    handleUpdateChapter: () => void;
    handlePublishChapter: () => void;
    handleSaveButtonOnClick: () => void;
}

const EditTitleBar: React.FC<EditTitleBarProps> = ({ editWorkTitle, handleUpdateChapter, handlePublishChapter, handleSaveButtonOnClick }) => {
    const { isMobile, isDesktop, notDesktop } = useContext(ViewportProviderContext);
    const navigate = useNavigate();

    const navigateToWorkBench = () => {
        navigate('/work/bench');
    };

    return (
        <header className={`w-full h-14 bg-[#f8f7f6] gap-4 whitespace-nowrap flex justify-center items-center relative ${isDesktop ? "px-10" : "px-[24px] z-50"} select-none`}>
            <BackButton title={isDesktop ? "退出編輯模式" : "退出"} onClickFunction={navigateToWorkBench} className={`${isDesktop?" w-32":  "w-16" }`}/>

            <div className='truncate flex-1 text-center'>
                {editWorkTitle ? editWorkTitle : "未命名"}
            </div>

            {isDesktop ? (
                <div className='flex gap-10 w-32'>
                    <span className='cursor-pointer py-2' onClick={handleUpdateChapter}>儲存為草稿</span>
                    <span className='cursor-pointer py-2' onClick={handlePublishChapter}>發佈</span>
                </div>
            ) : (
                <span className='cursor-pointer py-2 whitespace-nowrap w-16 flex justify-end' onClick={handleSaveButtonOnClick}>儲存</span>
            )}
        </header>
    );
};

export default EditTitleBar;