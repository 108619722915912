import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CollectibleProps, CollectibleVo } from "../../vo/collectible.vo";
import { collectibleFindStarApi } from "../../api/collectible/findStar";

interface StarState {
    initialCollectibles: CollectibleVo[] | null
    carouselCollectibles: CollectibleProps[] | null
    collectibleTypeId: number
    workTypeId: number | "all"
    workTagId: number | "all"
}

const initialState: StarState = {
    initialCollectibles: null,
    carouselCollectibles: null,
    collectibleTypeId: 1,
    workTypeId: "all",
    workTagId: "all",
}

// 获取所有书籍
export const collectibleFindStarAsync = createAsyncThunk(
    "star/collectibleFindStarAsync",
    async (userId: number) => {
        return await collectibleFindStarApi(userId) 
    }
)

export const starSlice = createSlice({
    name: "star",
    initialState,
    reducers: {
        setCollectibleTypeId: (state, action: PayloadAction<number>) => ({ ...state, collectibleTypeId: action.payload }),
        setWorkTagId: (state, action: PayloadAction<number | "all">) => ({ ...state, workTagId: action.payload  }),
        setWorkTypeId: (state, action: PayloadAction<number | "all">) => ({ ...state, workTypeId: action.payload  }),
        setCarouselCollectibles: (state, action: PayloadAction<CollectibleProps[]>) => ({ ...state, carouselCollectibles: action.payload  }),
    },
    extraReducers(builder) {
        builder
        // collectibleFindStarAsync
        .addCase(collectibleFindStarAsync.pending, (state, action) => {
            state.initialCollectibles = []
        })
        .addCase(collectibleFindStarAsync.fulfilled, (state, action) => {
            if(action.payload) {
                state.initialCollectibles = action.payload
            }
        })
    }
})

export const {
    setCollectibleTypeId,
    setWorkTypeId,
    setWorkTagId,
    setCarouselCollectibles
} = starSlice.actions