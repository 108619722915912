import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserVo } from "../../vo/user.vo";
import { accountFindUniqueApi } from "../../api/account/findUnique";

interface AccountState {
    accounts: UserVo[]
}

const initialState: AccountState = {
    accounts: []
}

// 根据 id 获取作者
export const accountFindUniqueAsync = createAsyncThunk(
    "read/accountFindUniqueAsync",
    async (id: number) => {
        return await accountFindUniqueApi(id)
    }
)

export const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
        // accountFindUniqueAsync
        .addCase(accountFindUniqueAsync.fulfilled, (state, action) => {
            if(action.payload) {
                if(!state.accounts.find(account => account.id === action.payload.id)) {
                    state.accounts.push(action.payload)
                }
            }
        })
    }
})