import React, { SyntheticEvent } from 'react';

interface MyImageProps {
    src: string
    subSrc?: string
    className: string
    isErrorOpacity?: boolean 
    style?: React.CSSProperties
    alt?: string
}

const MyImage: React.FC<MyImageProps> = ({ src, subSrc = "", className, isErrorOpacity = false, style, alt = "" }) => {
    // method: 图片加载失败
    const imageError = (e: SyntheticEvent<HTMLImageElement, Event>, subSrc: string) => {
        const img = e.currentTarget
        // 防止替换图片也加载失败 循环调用 overflow
        if(img.src === subSrc) {
            return img.onerror = null
        }            
        img.src = subSrc
    }

    // method: 图片加载成功
    const imageLoad = (e: SyntheticEvent<HTMLImageElement, Event>) => {
        const img = e.currentTarget
        if(img.src !== subSrc) {
            img.style.opacity = "1"
        } else {
            img.style.opacity = ".3"
        }
    }
  
    return (
        <img
            src={src} 
            onLoad={isErrorOpacity ? imageLoad : undefined}
            onError={subSrc ? (e) => imageError(e, subSrc) : undefined}
            className={className}
            style={style}
            alt={alt || "图片"}
        />
    )
}

export default MyImage;