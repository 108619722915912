import { CollectibleProps, CollectibleVo } from "../vo/collectible.vo"

// methods: 解析成标准藏品
export const parseNormal = (initialCollectibles: CollectibleVo[]) => {
    return initialCollectibles.map(initialCollectible => {
        const { id, amountCents, author_name, is_shelf, chapter_id, content, tag_id, total, current, work_title, work_type_id } = initialCollectible
        return {
            id, 
            price: amountCents / 100,
            author: author_name,
            is_shelf,
            chapter_id,
            content,
            workTagId: tag_id,
            workTypeId: work_type_id,
            book: work_title,
            total,
            count: total - current,
            allowLink: true
        }
    })
}

// methods: 解析成已收藏的藏品
export const parseStar = (initialCollectibles: CollectibleVo[]): CollectibleProps[] => {
    return initialCollectibles.map(initialCollectible => {
        const { author_name, work_title, chapter_id, content, id, is_shelf, tag_id, work_type_id, sign, created_at } = initialCollectible
        return {
            author: author_name,
            book: work_title,
            chapter_id,
            content,
            id,
            is_shelf,
            workTagId: tag_id,
            workTypeId: work_type_id,
            allowLink: true,
            sign,
            starDate: created_at
        }
    })
}