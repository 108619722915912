import ReactDOM from 'react-dom/client';
import './assets/css/main.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom'
import AntdProvider from './providers/AntdProvider';
import AuthProvider from './providers/AuthProvider'
import { Provider as ReduxProvider } from 'react-redux'
import { store } from './redux';
import ViewportProvider from './providers/ViewportProvider'
 
const root:ReactDOM.Root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <Router>
      <ViewportProvider>
        <ReduxProvider store={store}>
          <AntdProvider>
            <AuthProvider>
              <App />
            </AuthProvider>
          </AntdProvider>
        </ReduxProvider>
      </ViewportProvider>
    </Router>
  // </React.StrictMode>
);
