  import React from 'react';
  import { Drawer } from 'antd';
import XSperator from '../base/XSeperator';
  
  interface MobileSaveMenuProps {
      savePanelVisible: boolean;
      setSavePanelVisible: (visible: boolean) => void;
      handleUpdateChapter: () => void;
      handlePublishChapter: () => void;
  }
  
  const MobileSaveMenu: React.FC<MobileSaveMenuProps> = ({ savePanelVisible, setSavePanelVisible, handleUpdateChapter, handlePublishChapter }) => {
      const onClose = () => {
          setSavePanelVisible(false);
      };
  
      return (
          <Drawer
              placement={"top"}
              open={savePanelVisible}
              getContainer={false}
              onClose={onClose}
              title={null}
              closeIcon={false}
              zIndex={40}
              height = {165}
          >
            <div className='flex flex-col gap-6 p-2'>
              <div className='flex w-full justify-center relative tracking-wider'>
                  <div className={`text-base cursor-pointer`} onClick={handleUpdateChapter}>儲存為草稿</div>
              </div>
              <XSperator />
              <div className='flex w-full justify-center relative tracking-wider'>
                  <div className={`text-base cursor-pointer text-primary`} onClick={handlePublishChapter}>發佈章節</div>
              </div>
            </div>
          </Drawer>
      );
  };
  
  export default MobileSaveMenu;