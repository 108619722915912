import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import MyTabs from '../../components/my/MyTabs'
import { useLocation, useNavigate } from 'react-router-dom'
import Banner from '../../components/base/Banner'
import LeftSVG from '../../components/svgs/LeftSVG'
import { Button, GetProp, Row, UploadFile, UploadProps } from 'antd'
import BookCover from '../../components/book/BookCover'
import { WorkInfoDetail } from '../../views/work/detail/info'
import { WorkCategoryDetail } from '../../views/work/detail/category'
import { ScrollToTopLink } from '../../components/base/ScrollToTopLink'
import { AntdProviderContext } from '../../providers/AntdProvider'
import { useAppDispatch } from '../../redux'
import useAccessToken from '../../hooks/useAccessToken'
import { workCreateAsync, workUpdateAsync } from '../../redux/modules/workSlice'

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

export interface WorkDetailForm {
    id: number | null
    cover: UploadFile | null
    subCover: string
    title: string
    description: string
    workTypeId: number | null
    mainTagId: number | null
    subTagIds: number[]
    progress: number
    patternId: number
}

export const WorkDetail: React.FC = memo(() => {
    // router
    const navigate = useNavigate()
    const location = useLocation()

    // watch location
    useEffect(() => {
        if(location.state) {
            location.state.form && setForm(location.state.form)
            location.state.type && setType(location.state.type)
        }
    }, [location])

    // responsive data
    const [type, setType] = useState<'add' | 'edit'>()
    const [form, setForm] = useState<WorkDetailForm>({
        id: null,
        cover: null,
        subCover: '',
        title: '',
        description: '',
        workTypeId: null,
        mainTagId: null,
        subTagIds: [],
        progress: 0,
        patternId: 1
    })

    // computed
    const headerTitle = useMemo(() => {
        switch(type) {
            case 'add': return '新增作品'
            case 'edit': return '作品設置'
            default: return ''
        }
    }, [type])
    const submitTitle = useMemo(() => {
        switch(type) {
            case 'add': return '新增'
            case 'edit': return '更改'
            default: return ''
        }
    }, [type])

    // redux
    const dispatch = useAppDispatch()

    // context
    const { messageApi } = useContext(AntdProviderContext)

    // hook
    const { accessToken } = useAccessToken()

    // method: submit
    const handleSubmit = useCallback(async () => {
        const { id, cover, description, mainTagId, patternId, progress, subTagIds, title, workTypeId } = form
        if(!title) return messageApi.warning("請填寫書本名稱")
        if(!description) return messageApi.warning("請填寫作品簡介")
        if(!workTypeId) return messageApi.warning("請填寫書籍類別")
        if(!mainTagId) return messageApi.warning("請填寫主類別標籤")
        if(subTagIds.length < 1) return messageApi.warning("類別標籤至少保留 1 個") 
        if(!progress) return messageApi.warning("進度不允許為 0")
        const formData = new FormData()
        if(cover?.originFileObj) formData.append("cover", cover.originFileObj as FileType)
        formData.append("title", title)
        formData.append("description", description)
        formData.append("work_type_id", workTypeId.toString())
        formData.append("main_tag_id", mainTagId.toString())
        formData.append("progress", progress.toString())
        formData.append("pattern_id", patternId.toString())
        subTagIds.forEach((subTagId, index) => formData.append(`sub_tag_ids[${index}]`, subTagId.toString()))
        formData.append("noCover", patternId === 0 ? "false" : "true")
        switch(type) {
            case 'add': {
                return dispatch(workCreateAsync({ body: formData, accessToken })).then(() => navigate("/work/bench"))
            }
            case 'edit': {
                if(id) {
                    return dispatch(workUpdateAsync({ id, body: formData, accessToken })).then(() => navigate("/work/bench"))
                }
            }
        }
    }, [form, accessToken, dispatch, messageApi, navigate, type])

    return (
        <main className='bg-white mb-16'>
            <Banner title='工作台'></Banner>
            <header className='flex items-center relative text-xl'>
                <section onClick={() => navigate('/work/bench')} className='w-12 h-12 flex justify-center items-center cursor-pointer'>
                    <LeftSVG />
                </section>
                <b className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                    { headerTitle }
                </b>
            </header>
            <MyTabs type='steps' tabsClassName='mx-auto gap-10 mb-5'>
                <MyTabs.Item headerSlot>
                    <section className='w-full mb-10 flex-col items-center'>
                        <Row className='w-3/5 max-w-80 mb-5 rounded-lg bg-[#ebeee8] mx-auto'>
                            <BookCover title={form.title} coverUrl={form.subCover} main_tag_id={Number(form.mainTagId)}/>
                        </Row>
                        <Row className='flex justify-center'>
                            <ScrollToTopLink to='/work/cover' state={{ form, type }}>
                                <Button className='w-32 h-12 bg-white border-solid border-2 border-primary text-sm'>更改封面</Button>
                            </ScrollToTopLink>
                        </Row>
                    </section>
                </MyTabs.Item>
                <MyTabs.Item title='書本資訊'>
                    <WorkInfoDetail {...{ form, setForm }} />
                </MyTabs.Item>
                <MyTabs.Item title='書本類別及編寫進度'>
                    <WorkCategoryDetail {...{ form, setForm, submitTitle, handleSubmit }} />
                </MyTabs.Item>
            </MyTabs>
        </main>
    )
})