import React, { memo } from 'react'

interface FlowerTwoSVGProps {
  color: string
}

const FlowerTwoSVG: React.FC<FlowerTwoSVGProps> = memo(({ color }) => {
  return (
    <svg width="70" height="149" viewBox="0 0 70 149" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_504_23596)">
<path fillRule="evenodd" clipRule="evenodd" d="M43.9301 53.558C47.7147 50.7245 47.1601 36.5573 50.4227 31.6721C54.8761 32.5514 58.677 30.1251 62.2659 27.8779C65.1859 26.6566 68.7747 25.5818 68.7421 21.7713C69.7698 16.365 64.0277 8.14149 58.1224 11.0889C48.9055 16.9186 49.7701 18.7913 45.3167 27.5848C43.7017 27.5196 43.7833 29.4737 43.6201 30.6136C43.3102 32.9585 42.9187 35.2872 42.4293 37.5832C41.6952 40.8889 41.3037 44.276 41.2221 47.6631C41.1079 49.7964 40.4881 54.3559 43.9464 53.5743L43.9301 53.558ZM43.4407 50.3012C43.2939 43.5595 45.0883 37.0621 45.9692 30.4345C46.1323 30.5159 46.2955 30.5973 46.4586 30.6625C46.9806 30.8905 47.5026 31.0696 48.0246 31.2161C45.8387 35.7594 45.5614 41.1169 44.4358 45.9533C44.1422 47.4189 43.8648 48.8844 43.4407 50.3012ZM51.5809 29.4412C50.1616 29.3272 48.7424 29.1318 47.4374 28.5455C49.7701 24.9467 50.3085 20.2569 53.6363 17.3258C58.0897 14.0689 60.9282 9.62335 65.2022 16.1533C66.703 18.2702 67.1108 21.4782 65.6753 23.7091C64.1255 24.8327 62.1027 25.1584 60.4714 26.2006C57.7635 27.8779 54.974 29.8808 51.5972 29.4412H51.5809Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M45.4967 30.1413C48.4493 28.9363 42.1362 22.7972 41.239 21.1362C38.7757 18.2702 37.3075 15.111 36.7366 11.4145C36.2798 9.23245 34.6812 7.31092 34.7627 5.04743C35.5294 1.25322 41.0432 2.11628 43.6859 3.33759C45.3009 4.13551 46.3123 5.66622 47.4705 6.98524C49.9501 9.15103 51.1083 12.0333 50.7984 15.2902C50.7984 16.1858 50.0317 17.3094 51.0757 17.9119C51.5977 18.2213 52.3807 18.0585 52.6744 17.4885C53.6858 14.4271 53.1638 10.584 51.3856 7.88087C49.0366 5.4871 47.2585 2.21398 44.0122 0.94382C40.407 -0.521754 34.6648 -0.717164 32.7725 3.38644C31.6633 6.04076 33.2782 8.66251 34.1918 11.1051C34.8932 13.3361 35.1053 15.7135 36.2146 17.7979C38.0906 21.3479 40.9127 24.2953 43.1476 27.6173C43.9306 28.4478 43.9796 30.467 45.4967 30.1413Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M62.2334 12.198C65.969 8.22469 62.7554 1.59704 57.9104 0.424576C55.2351 -0.373347 53.2612 1.79245 51.5484 3.486C50.0639 4.46305 48.3673 8.59922 51.271 8.58294C51.8909 8.4201 52.2988 7.68731 52.0541 7.06851C52.364 5.27725 54.762 3.50228 56.2954 2.62294C56.2954 2.62294 56.3281 2.62294 56.3444 2.62294C56.377 2.62294 56.3933 2.62294 56.4259 2.62294C56.4096 2.62294 56.377 2.62294 56.3607 2.62294C56.3607 2.62294 56.3607 2.62294 56.2791 2.63922C59.297 2.37867 62.1844 6.10775 61.434 8.95747C61.2056 9.62512 60.8304 10.2276 60.39 10.7813C59.4602 11.97 61.3198 13.403 62.2334 12.198Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M62.5282 37.0794C69.2817 36.8189 72.9359 28.8071 63.7843 28.7745C62.104 28.7582 58.8578 26.0225 58.2868 28.7256C58.2542 30.1586 61.2721 30.2563 62.3814 30.8263C63.4417 31.4777 68.0093 30.6635 66.639 32.7478C65.6766 33.8389 64.1758 34.5879 62.7239 34.7345C58.3357 35.2067 53.9476 33.4969 50.375 31.0868C49.2005 30.1586 47.7486 32.015 48.9558 32.9269C52.9035 35.5976 57.7322 37.5028 62.5282 37.0631V37.0794Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M45.4959 28.4151C45.8385 28.236 45.8385 27.8289 45.708 27.5032C45.0718 24.6209 44.876 21.6246 43.8809 18.8238C43.4242 17.4885 42.9185 16.2672 42.3475 14.997C41.8744 13.906 41.3687 12.8312 40.8467 11.7564C40.7815 11.6262 40.6836 11.5122 40.5368 11.447C40.08 11.2354 39.5417 11.7564 39.7701 12.2124C41.3524 15.4855 42.9511 18.8726 43.6199 22.4389C43.9462 24.0836 44.1419 25.7608 44.4845 27.4055C44.5008 27.5358 44.5661 27.6823 44.5661 27.8126C44.5661 28.2686 45.0718 28.6594 45.4959 28.3989V28.4151Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M46.5899 28.4161C47.3566 24.6545 47.275 20.7951 46.8998 17.0009C46.8019 15.7959 46.5735 14.6071 46.2962 13.4184C46.2799 13.0601 45.9863 12.767 45.6274 12.8159C45.3174 12.8647 45.0727 13.1579 45.1217 13.4673C45.921 16.9032 46.0678 20.4532 45.97 23.9542C45.8721 25.3873 45.6926 26.8365 45.4153 28.2533C45.3174 29.0023 46.4594 29.1652 46.5735 28.4161H46.5899Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M43.8336 9.28072C43.8826 8.75963 43.7031 7.79886 42.9853 7.9617C42.6754 8.04313 42.4796 8.36881 42.5775 8.67821C42.7407 9.08531 42.5938 9.68783 43.1811 9.76925C43.491 9.8181 43.7847 9.59012 43.8336 9.26444V9.28072Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M54.5995 14.5415C55.4152 12.1314 56.867 10.0633 58.482 8.12551C58.7593 7.76725 59.1019 7.36015 58.9877 6.87163C58.8246 6.25283 57.8621 6.35053 57.8458 6.98562C56.7202 8.58146 55.3336 10.0308 54.4853 11.822C54.1265 12.5711 53.8002 13.3201 53.5066 14.0855C53.3924 14.3786 53.5066 14.7206 53.8165 14.8509C54.0938 14.9649 54.469 14.8509 54.5832 14.5415H54.5995Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M52.9353 13.9404C53.1148 13.8427 53.18 13.6636 53.2453 13.4844C53.7184 12.2143 54.1588 10.8138 55.1539 9.86935C55.3986 9.67395 55.4638 9.31569 55.2518 9.05515C54.844 8.55034 54.273 9.00629 53.9794 9.39711C53.0332 10.5207 52.5438 11.9212 52.0707 13.289C51.9076 13.7613 52.5112 14.2172 52.919 13.9404H52.9353Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M49.3141 30.0118C51.6469 29.9792 53.7023 28.4973 55.5457 27.276C57.6501 25.8919 59.6892 24.3449 61.4673 22.5862C61.663 22.3419 61.6141 21.9674 61.3531 21.772C61.1247 21.5928 60.7821 21.6254 60.5864 21.8208C60.1786 22.2279 59.7055 22.635 59.2977 22.9933C56.7855 25.0777 54.0612 27.162 51.0596 28.4973C50.2113 28.823 49.2978 28.8881 48.3843 28.823C48.0743 28.823 47.797 29.0998 47.797 29.4092C47.8297 30.1746 48.7758 29.9792 49.3141 30.0118Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M48.7425 28.9862C50.9448 28.5303 52.5434 26.7065 54.24 25.3549C54.5826 25.0292 55.284 24.8175 55.0393 24.2313C54.5989 23.4008 53.7343 24.2476 53.2938 24.6384C52.3477 25.4526 51.4015 26.2831 50.3738 26.9996C49.8192 27.3253 49.2808 27.7161 48.6446 27.8301C48.1063 27.8464 47.568 27.6998 47.0623 27.5532C46.7523 27.4718 46.4261 27.6509 46.3445 27.9603C46.0835 28.9048 48.1226 28.9862 48.7425 28.9862Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M57.3893 21.6893C57.8297 21.2985 58.8411 20.7123 58.2865 20.0446C57.6339 19.4747 57.1772 20.4843 56.6715 20.7611C56.0679 21.2333 56.7857 22.1453 57.3893 21.6893Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M61.7933 15.6336C62.5437 15.2103 63.3267 14.852 64.126 14.6403C64.4523 14.6403 64.7785 14.4123 64.7785 14.0378C64.7459 13.3213 63.9629 13.419 63.4735 13.6144C62.6578 13.875 61.9238 14.2332 61.206 14.624C60.9287 14.7706 60.8471 15.1451 60.9939 15.4219C61.1571 15.6988 61.5159 15.7802 61.7933 15.6336Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M37.7803 4.78848C38.5797 4.38137 37.7477 3.35547 37.5683 2.85066C37.3073 2.31328 37.0136 1.80848 36.72 1.28738C36.5732 1.01055 36.1817 0.929131 35.9207 1.07569C35.1213 1.64563 36.3285 2.68782 36.5079 3.33919C36.8505 3.84399 36.9158 5.06531 37.764 4.78848H37.7803Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M66.769 33.4804C67.0626 33.399 67.2747 33.0733 67.1768 32.7639C67.0789 32.4057 66.6874 32.2917 66.3612 32.2917C65.464 32.2591 64.5504 32.308 63.6532 32.308C61.8588 32.308 59.9665 32.2754 58.2047 32.1289C57.2585 32.0637 56.2961 32.0149 55.3499 31.9334C53.5066 31.8195 51.8589 30.9238 50.2603 30.0771C49.6078 29.7025 49.0205 30.6959 49.673 31.0867C51.2717 31.9172 52.8867 32.7965 54.6974 33.0245C55.8067 33.171 56.8996 33.1873 57.96 33.2687C60.8963 33.5293 63.849 33.4153 66.769 33.4642V33.4804Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M46.6871 27.5206C48.8567 26.1853 50.0476 23.7101 51.4342 21.6583C53.196 18.662 54.9904 15.796 57.6494 13.6465C54.0606 14.3141 51.0264 16.9522 49.5582 20.2416C48.6773 21.8211 47.6822 26.9669 46.2304 27.488C46.1325 27.4717 46.0183 27.3577 45.953 27.3415C46.1488 27.5857 46.4098 27.6997 46.6871 27.5206Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M45.6757 27.847C43.7997 25.3555 42.2337 22.75 40.7655 19.998C39.9825 18.4184 39.2321 16.79 38.6448 15.129C38.2533 14.0054 37.8781 12.8655 37.2909 11.8396C36.671 10.7649 35.8553 9.85298 35.1539 8.85964C35.0234 8.68052 34.9092 8.46882 34.795 8.32227C35.1049 9.64128 35.6433 10.8789 35.9206 12.1979C36.3284 14.1357 36.0348 16.1387 36.4589 18.0602C37.4703 23.0594 42.2826 25.3555 45.9041 28.2378C45.8878 28.2378 45.8715 28.2378 45.8552 28.2378C45.8389 28.2378 45.8715 28.2378 45.8878 28.2378C46.0509 28.2378 45.7084 27.847 45.6921 27.8144L45.6757 27.847Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M47.1115 147.291C47.0952 141.966 44.6646 136.445 41.7119 132.032C40.8147 130.762 36.2145 125.128 34.7626 126.675C34.3058 127.131 34.3222 127.864 34.7626 128.32C40.3743 132.309 43.4085 138.823 44.7461 145.402C45.0235 146.248 44.5504 147.47 45.5781 147.909C46.1654 148.154 46.8342 147.877 47.1115 147.291Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M26.1654 131.463C28.3187 131.268 30.3905 130.551 32.4459 129.916C34.0446 129.428 36.5894 129.021 36.1 126.773C35.6595 125.406 34.3056 122.474 32.7722 122.328C29.9011 118.566 25.4313 116.759 20.7821 116.547C17.6827 116.368 14.0938 115.26 11.4022 117.361C8.40058 119.739 7.56862 124.347 9.03679 127.783C11.5 132.652 21.3857 131.3 26.1328 131.447L26.1654 131.463ZM21.4999 129.183C18.1884 128.727 13.4902 129.819 11.2064 126.871C10.0808 124.2 10.7496 120.39 13.3923 118.859C16.8507 117.866 24.0284 118.99 27.3073 120.569C29.2322 121.351 30.1784 123.826 32.087 124.363C32.7069 125.21 33.392 126.057 33.7836 127.05C29.8195 128.434 25.7413 129.623 21.4999 129.183Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M37.9772 127.781C38.3035 124.345 38.124 120.974 37.5857 117.571C37.7651 113.744 37.0147 109.95 34.4862 106.954C31.6478 102.476 24.1438 99.4792 20.1961 104.169C18.9073 105.488 17.9938 107.279 18.3527 109.168C18.7932 112.116 20.1634 114.819 21.2564 117.571C21.5337 118.141 22.1699 118.45 22.7898 118.19C24.6984 117.457 21.599 113.093 21.3543 111.562C19.7393 108.37 21.2075 104.674 24.8779 104.104C29.6739 104.055 33.1975 107.98 34.7635 112.181C35.3671 113.972 35.1714 115.877 35.2692 117.717C35.7423 121.072 36.0033 124.41 35.6444 127.797C35.6281 129.296 37.9935 129.312 37.9772 127.797V127.781Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M9.91875 125.388C9.96769 123.352 4.91067 124.688 3.312 122.994C0.58773 120.226 3.60563 115.976 5.93838 113.973C10.9138 111.514 14.6658 113.68 18.1894 117.278C19.364 118.207 20.8158 116.35 19.6086 115.438C17.3411 113.517 15.0573 110.928 11.9089 110.765C9.49461 110.651 6.88453 110.618 4.76385 111.97C-0.130041 115.178 -2.95218 124.623 4.76385 126.121C6.26464 126.235 9.49461 127.586 9.90244 125.404L9.91875 125.388Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M44.8597 149C46.4257 149.114 46.5399 146.655 44.9576 146.672C43.9298 146.525 43.7015 145.092 43.3099 144.294C41.9397 139.816 40.6346 135.289 37.8451 131.478C37.1436 130.534 36.7032 129.362 35.8223 128.564C34.6641 127.652 33.2122 129.427 34.3541 130.371C36.3117 133.351 38.3508 136.315 39.4111 139.751C40.8467 142.796 40.6999 148.43 44.8434 149.016L44.8597 149Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M17.5371 140.956C22.5126 139.898 26.2809 135.778 30.8648 133.856C32.1046 133.254 33.3281 132.602 34.682 132.277C35.3998 132.244 36.1665 131.853 36.1502 131.055C35.5793 127.131 24.1765 135.11 22.3494 136.234C19.0542 138.546 13.7199 140.907 11.8765 135.745C11.1261 134.394 10.3431 133.237 10.441 131.674C10.2941 130.323 12.2354 128.759 10.7835 127.733C8.69548 126.952 8.28765 130.388 8.10821 131.821C8.49972 136.331 12.2028 142.78 17.5534 140.956H17.5371Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M34.795 127.034C35.2191 126.432 34.4524 126.025 34.012 125.78C33.2126 125.308 32.3807 124.869 31.5487 124.461C27.7315 122.459 23.5227 121.319 19.2324 120.928C18.482 120.83 18.3189 121.97 19.0693 122.084C23.327 122.459 27.4215 123.615 31.1735 125.585C32.087 126.057 33.0006 126.53 33.8651 127.083C34.1261 127.344 34.5666 127.36 34.795 127.067V127.034Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M34.7636 127.586C35.0246 126.869 34.1274 126.69 33.6217 126.527C32.7082 126.267 31.7946 126.006 30.8811 125.746C26.1667 124.068 21.9742 124.134 17.0803 124.834C16.3299 124.948 16.4768 126.071 17.2435 125.99C20.4408 125.599 23.5729 125.257 26.7376 125.827C29.0867 126.397 31.3868 127.097 33.7032 127.781C34.0621 127.977 34.5678 128.026 34.7473 127.586H34.7636Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M13.0181 125.225C13.5565 125.225 14.4537 125.03 14.1764 124.297C14.0295 123.955 13.6543 123.874 13.3444 124.004C12.9366 124.086 12.5124 124.069 12.1372 124.037C11.3705 123.955 11.2237 125.079 11.9741 125.193C12.3167 125.225 12.6756 125.242 13.0181 125.209V125.225Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M12.9039 117.751C13.9643 116.09 9.11932 116.009 8.25473 116.253C7.94478 116.302 7.71641 116.595 7.74903 116.904C7.87954 117.637 8.69518 117.344 9.20089 117.311C10.147 117.263 11.1258 117.311 12.0067 117.702C12.2514 117.93 12.6429 118.012 12.9039 117.751Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M9.9675 119.948C10.1306 118.662 6.75384 119.509 5.88926 119.199C5.13886 119.102 4.97573 120.241 5.72612 120.355C6.91697 120.567 8.10782 120.29 9.29867 120.453C9.60861 120.518 9.91856 120.258 9.95118 119.948H9.9675Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M30.8966 120.762C31.1576 120.616 31.2718 120.241 31.1086 119.964C30.2114 118.564 29.2653 117.196 28.3517 115.845C25.5948 112.262 25.4154 108.468 25.5133 104.136C25.5133 103.387 24.3551 103.371 24.3551 104.136C24.2082 110.129 24.7139 113.093 28.3844 117.945C28.9716 118.808 29.5426 119.688 30.1135 120.567C30.293 120.844 30.6356 120.942 30.9129 120.779L30.8966 120.762Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M34.2414 125.274C34.535 125.192 34.7471 124.867 34.6492 124.557C33.8499 121.691 32.5285 118.988 31.4845 116.203C31.1745 115.243 30.9298 114.266 30.832 113.256C30.7993 112.947 30.4731 112.719 30.1794 112.751C29.2333 112.963 29.9021 114.412 29.9674 115.064C30.5873 117.783 32.0228 120.226 32.8711 122.88C33.0995 123.548 33.3115 124.199 33.5236 124.867C33.6215 125.176 33.9314 125.355 34.2414 125.274Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M29.9011 109.673C30.2111 109.591 30.3905 109.266 30.3089 108.956C30.0805 108.126 29.8848 107.295 29.7706 106.449C29.6727 105.781 29.6401 105.113 29.6075 104.446C29.6075 104.136 29.3465 103.859 29.0202 103.859C28.7103 103.859 28.4329 104.12 28.4329 104.446C28.4656 105.26 28.5308 106.074 28.645 106.888C28.7592 107.686 28.955 108.484 29.167 109.266C29.2486 109.575 29.5912 109.754 29.8848 109.673H29.9011Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.1053 136.754C19.5424 136.542 23.6859 134.8 27.65 132.895C29.6891 131.917 31.924 131.429 33.9794 130.582C34.2731 130.468 34.4036 130.11 34.2894 129.817C33.8652 129.003 32.8538 129.882 32.2176 129.996C30.88 130.468 29.5423 130.843 28.2209 131.364C25.7577 132.471 23.3107 133.627 20.7496 134.523C18.8736 135.158 16.916 135.435 14.9585 135.598C14.2081 135.712 14.3549 136.835 15.1216 136.754H15.1053Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M13.1146 134.688C17.2744 134.215 15.3984 131.903 14.6154 133.092C13.604 133.564 12.4621 133.629 11.3528 133.564C10.9123 133.499 10.2761 133.434 10.1946 134.02C10.1456 134.329 10.374 134.639 10.7003 134.671C11.5159 134.753 12.3152 134.785 13.1309 134.688H13.1146Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M21.681 132.667C24.0464 132.585 26.3628 131.983 28.5325 131.071C30.3758 130.289 32.0398 129.166 33.8342 128.27C34.1278 128.14 34.4214 127.96 34.7314 127.993C34.6988 127.993 34.6498 127.993 34.6009 128.009C34.6661 128.009 34.7803 128.042 34.7803 127.977C34.7314 127.83 34.3725 127.863 34.2746 127.879C32.937 127.993 31.6319 128.302 30.2943 128.449C24.8457 129.052 15.5637 130.077 10.6534 127.7C10.1804 127.342 9.87043 126.788 9.72361 126.234C9.64204 126.739 9.72361 127.293 9.90305 127.781C11.9095 131.071 17.978 132.667 21.6484 132.667H21.681Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M19.3479 141.559C24.6985 140.468 31.8599 130.355 35.5956 130.664C35.5793 130.664 35.5466 130.664 35.5303 130.664C35.563 130.664 35.6119 130.664 35.6282 130.648C35.3346 130.355 34.7636 130.453 34.3558 130.485C32.4635 130.778 30.7017 131.576 28.9888 132.391C25.4163 133.97 22.431 136.462 19.3968 138.855C16.9988 140.549 13.4752 139.572 11.6808 137.439C12.7901 140.386 16.2647 142.177 19.3479 141.542V141.559Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M37.4716 127.082C37.5695 127.066 37.4553 126.789 37.4064 126.773C37.4227 126.822 37.4227 126.887 37.439 126.952C36.346 122.832 38.3036 118.761 37.9773 114.609C37.7979 110.033 35.1715 105.245 30.4734 104.105C31.0117 104.529 31.599 105.001 32.072 105.538C35.5141 109.463 34.3559 115.065 34.9431 119.852C35.0736 121.643 35.6935 123.337 36.3787 124.982C36.7212 125.682 36.9007 126.561 37.4553 127.082H37.4716Z" fill={color}/>
</g>
<defs>
<clipPath id="clip0_504_23596">
<rect width="69.2649" height="149" fill={color} transform="matrix(-1 0 0 1 69.2649 0)"/>
</clipPath>
</defs>
</svg>

  )
})

export default FlowerTwoSVG