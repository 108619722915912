import React, { memo, useContext } from 'react'
import { Dropdown, MenuProps } from 'antd'
import { ScrollToTopLink } from '../base/ScrollToTopLink'
import { AuthProviderContext } from '../../providers/AuthProvider'
import { RootState, useAppSelector } from '../../redux'
import { useAuthentication } from '../../hooks/useAuthentication'
import MyAvatar from '../my/MyAvatar'
import { useLanguage } from '../../hooks/useLanguage'

const User: React.FC = memo(() => {
  // redux
  const { user } = useAppSelector((state: RootState) => state.authReducer)

  // context
  const { logout } = useContext(AuthProviderContext)

  // hooks
  const { stayTuned } = useAuthentication()
  const { t } = useLanguage()

  // other data
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <ScrollToTopLink to={`/user/${user?.id}`}>
          <section className='w-24 h-10 flex justify-center items-center text-sm'>
            { t()['header']['avatar']['user'] }
          </section>
        </ScrollToTopLink>
      )
    },
    {
      key: '2',
      label: (
        <ScrollToTopLink to='/user/setting'>
          <section className='w-24 h-10 flex justify-center items-center text-sm'>
            { t()['header']['avatar']['setting'] }
          </section>
        </ScrollToTopLink>
      )
    },
    {
      key: '3',
      label: (
        // <ScrollToTopLink to='/user/notice'>
        <section onClick={stayTuned} className='w-24 h-10 flex justify-center items-center text-sm'>
          { t()['header']['avatar']['notice'] }
        </section>
        // </ScrollToTopLink>
      )
    },
    {
      key: '4',
      label: (
        <ScrollToTopLink to='/' callback={() => logout()}>
          <div className='w-24 h-10 flex justify-center items-center text-sm'>
            { t()['header']['avatar']['logout'] }
          </div>
        </ScrollToTopLink>
      )
    }
  ]
  
  return (
    <Dropdown
      menu={{ items }}
      placement="bottom"
      trigger={['click']}
    >
      <section className='flex'>
        <MyAvatar src={user?.avatar} />
      </section>
    </Dropdown>
  )
})

export default User