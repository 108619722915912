import React, { memo, useContext, useState } from 'react'
import MyButton from '../my/MyButton'
import { RootState, useAppSelector } from '../../redux'
import { useLocation } from 'react-router-dom'
import { ViewportProviderContext } from '../../providers/ViewportProvider'

interface NavBarProps {
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  changeWorkTypeId: (id: number | "all") => void
  changeWorkTagId: (id: number | "all") => void
}

// tip: 导航栏组件(根据 workType 和 workTag 进行导航)
const NavBar: React.FC<NavBarProps> = memo(({ setCurrentPage, changeWorkTypeId, changeWorkTagId }) => {
  // redux
  const { workTypes, workTags } = useAppSelector((state: RootState) => state.workReducer)

  // context
  const { notDesktop } = useContext(ViewportProviderContext)

  // router
  const location = useLocation()

  // responsive data
  const [selectedWorkTypeId, setSelectedWorkTypeId] = useState<number | "all">("all") 
  const [selectedWorkTagId, setSelectedWorkTagId] = useState<number | "all">(location.state || "all") 

  // method: 切换书籍类别
  const handelChangeBookCategory = (id: number | "all") => {
    setCurrentPage(1)
    setSelectedWorkTypeId(id)
    changeWorkTypeId(id)
  }
  
  // method: 切换书籍标签
  const handelChangeContentCategory = (id: number | "all") => {
    setCurrentPage(1)
    setSelectedWorkTagId(id)
    changeWorkTagId(id)
  }
  
  return (
    <main className='w-full h-auto flex flex-col gap-2 items-center'>
      {/* 书籍类别按钮 */}
      <section 
        className={[
          'max-w-full w-auto flex gap-2',
          notDesktop ? 'flex-nowrap overflow-auto px-2 pb-2' : 'flex-wrap'
        ].join(' ')}
      > 
        <MyButton 
          size='large'
          style={{ 
            backgroundColor: selectedWorkTypeId==="all"?"#b6aeac":"#f1eeed",
            color: selectedWorkTypeId==="all"?"#fff":"#102e24",
          }}
          onClick={ () => handelChangeBookCategory("all") }
          className={[
            'px-7 text-sm h-10',
            notDesktop ? 'rounded-full' : ''
          ].join(' ')}
        >全部</MyButton>
        {
          workTypes.map(workTag => {
            return (
              <MyButton 
                key={workTag.id} 
                size='large'
                style={{ 
                  backgroundColor: selectedWorkTypeId === workTag.id ? "#b6aeac" : "#f1eeed",
                  color: selectedWorkTypeId === workTag.id ? "#fff" : "#102e24" 
                }}
                onClick={() => handelChangeBookCategory(workTag.id)}
                className={[
                  'px-7 text-sm h-10',
                  notDesktop ? 'rounded-full' : ''
                ].join(' ')}
              >{ workTag.name }</MyButton>
            )
          })
        }
      </section>
      {/* 书籍标签按钮 */}
      <section
        className={[
          'max-w-full w-auto flex gap-2',
          notDesktop ? 'flex-nowrap overflow-auto px-2 pb-2' : 'flex-wrap'
        ].join(' ')}
      >
        <MyButton 
          type={ selectedWorkTagId === "all" ? "primary" : "default" }
          onClick={() => handelChangeContentCategory("all")}
          className={[
            'text-sm h-10',
            notDesktop ? 'rounded-full' : ''
          ].join(' ')}
        >全部</MyButton>
        {
          workTags.map(workTag => (
            <MyButton 
              key={ workTag.id } 
              type={ selectedWorkTagId === workTag.id ? "primary" : "default" }
              onClick={ () => handelChangeContentCategory(workTag.id) }
              className={[
                'text-sm h-10',
                notDesktop ? 'rounded-full' : ''
              ].join(' ')}
            >{ workTag.name }</MyButton>
          ))
        }
      </section>
    </main>
  )
})

export default NavBar