import React, { memo, useContext, useEffect, useState } from 'react'
import { Collectible } from '../../../components/collectible/Collectible'
import { Button, Input, Select } from 'antd'
import { collectibleFindApi } from '../../../api/collectible/find'
import { useNavigate, useParams } from 'react-router-dom'
import useAccessToken from '../../../hooks/useAccessToken'
import { CollectibleProps } from '../../../vo/collectible.vo'
import { parseStar } from '../../../utils/parseCollectibles'
import { RootState, useAppSelector } from '../../../redux'
import { CollectiblePurchaseBody, collectiblePurchaseApi } from '../../../api/collectible/purchase'
import { useAuthentication } from '../../../hooks/useAuthentication'
import QuotaDarkSVG from "../../../assets/svg/quota-dark.svg"
import { ViewportProviderContext } from '../../../providers/ViewportProvider'
import { useLanguage } from '../../../hooks/useLanguage'
import LeftSVG from '../../../components/svgs/LeftSVG'

export const CollectibleStar: React.FC = memo(() => {
  // router
  const navigate = useNavigate()
  const params = useParams() as Record<string, string>
  const id = parseInt(params.id)

  // redux
  const { isLogin } = useAppSelector((state: RootState) => state.authReducer)
  const { pleaseLogin } = useAuthentication()

  // hooks
  const { accessToken } = useAccessToken()
  const { t } = useLanguage()

  // context
  const { notDesktop } = useContext(ViewportProviderContext)

  // responsive data
  const [collectible, setCollectible] = useState<CollectibleProps | null>(null)
  const [message, setMessage] = useState<string>("")

  // other data
  const fontOptions = [
    { label: <div className="text-xl py-2 source-han-serif-hk">Source Han Serif HK</div>, value: 1 }
  ]

  // method: 收藏藏品
  const handlePurchase = async () => {
    const body: CollectiblePurchaseBody = { font_id: 1, message }
    if(collectible) {
      const { sessionUrl } = await collectiblePurchaseApi(collectible.id, body, accessToken)
      sessionUrl && window.open(sessionUrl)
    }
  }

  // mounted: fetch data
  useEffect(() => {
    async function fetchData() {
      const res = await collectibleFindApi({ id }, accessToken)
      const collectible = res.find(collectible => collectible.id === id)
      if(collectible) {
        setCollectible(parseStar([collectible])[0])
      }
    }
    fetchData()
  }, [accessToken, id])

  return (
    <main className={[
      'w-full flex bg-white rounded-xl',
      notDesktop ? 'flex-col gap-8 pt-5 pb-16 px-10 items-center' : 'gap-14 py-24 px-20'
    ].join(' ')}>
      { notDesktop && (
        <header className='w-full flex justify-start items-center gap-8'>
          <div onClick={() => navigate("/read")} className='flex items-center gap-2 cursor-pointer'>
            <LeftSVG />
            { notDesktop || <span className='text-xl'>{ t()['readPage']['chapterPage']['return'] }</span> }
          </div>
          <span className='text-2xl'>製造您的專屬收藏品！</span>
        </header>
      ) }
      <div className={[
        'h-full flex flex-col gap-4',
        notDesktop ? '' : 'w-[19rem]'
      ].join(' ')}>
          { collectible && <Collectible {...collectible}/> }
      </div>
      <div className='flex-1 h-auto flex flex-col gap-8 justify-end'>
          { notDesktop || <span className='text-2xl'>製造您的專屬收藏品！</span> }
          <div className='flex flex-col gap-4 text-base'>
              <span>字體</span>
              <Select 
                  options={fontOptions} 
                  defaultValue={1} 
                  className='h-12 text-base' 
              />
              <span>我的留言 (將於個人主頁的動態欄中發佈)</span>
              <Input 
                  value={message} 
                  onChange={(e) => 
                  setMessage(e.target.value)} placeholder='請輸入您的訊息' 
                  className='h-12 text-base'
              />
          </div>
          <Button 
              onClick={isLogin ? handlePurchase : pleaseLogin}
              type='primary' 
              className='w-full h-12 text-sm flex gap-2 justify-center items-center'
          >
              <img src={QuotaDarkSVG} alt="收藏按钮图标"/>
              <span>收藏</span>
          </Button>
      </div>
    </main>
  )
})
